// hook
import { useState, useEffect } from 'react';
import { useRequestGet, useRequestPatch, useRequestPost, useRequestDelete } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'api/axios';
// commons
import AnswerCard from 'components/cards/AnswerCard';
import ConyCommentCard from 'components/cards/ConyCommentCard';
import CogenLifeMainHeader from 'components/headers/CogenLifeMainHeader';
import NavBottom from 'components/Nav/NavBottom';
import ShareButton from 'components/buttons/ShareButton';
import SaveButton from 'components/buttons/SaveButton';
import CommentInput from 'components/inputs/CommentInput';
import EmptyCard from 'components/cards/EmptyCard';
// icons
import { HiMiniChatBubbleOvalLeft } from 'react-icons/hi2';
import { IoIosArrowUp } from 'react-icons/io';
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const CogenLifeCategoryId = () => {
    const paramId = useParams().id;
    const paramCategory = useParams().category;

    const patchBookmarks = useRequestPatch();
    // post
    const [currentSlide, setCurrentSlide] = useState(1);
    const [showFullImage, setShowFullImage] = useState('');
    const [isBookmarks, setIsBookmarks] = useState(false);

    // comment
    const [comment, setComment] = useState('');
    const [newCommentCnt, setNewCommentCnt] = useState(0);
    const [totalCommentCnt, setTotalCommentCnt] = useState(0);
    const [commentList, setCommentList] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태, 스타일 상으로는 아직 보이지 않도록 하겠음 1차
    const [isCommentWriteToggle, setIsCommentWriteToggle] = useState(false);

    const getUserInfo = useRequestGet({
        requestUrl: '/api/users/profile',
        queryKey: 'getUserInfo',
    });

    const getQuestionLifeId = useRequestGet({
        requestUrl: `api/posts/${paramId}`,
        queryKey: `getQuestionLifeId-${paramId}`, // 페이지 기반 캐시키 변경
    });

    const sortedImages = getQuestionLifeId?.data?.data?.images?.sort((a, b) => a.order - b.order) || [];
    // 북마크 기능

    useEffect(() => {
        if (getQuestionLifeId?.data?.data) {
            setIsBookmarks(getQuestionLifeId?.data?.data?.isBookmarked);
        }
    }, [getQuestionLifeId?.data?.data]);
    const handlePatchBookmarks = () => {
        const seq = getQuestionLifeId?.data?.data?.seq;
        setIsBookmarks(!isBookmarks);
        patchBookmarks.mutate({
            requestUrl: `/api/posts/bookmarks/toggle/${seq}`,
        });
    };

    // 댓글 작성 기능
    const postComment = useRequestPost();
    const handlePostComment = () => {
        if (comment) {
            const seq = getQuestionLifeId?.data?.data?.seq;
            postComment.mutate({
                requestUrl: `/api/posts/${seq}/comments`,
                postData: {
                    content: comment,
                },
            });
        }
    };
    useEffect(() => {
        if (postComment?.status === 'success') {
            setComment('');
            const newComment = {
                seq: postComment?.data?.data?.seq,
                content: postComment?.data?.data?.content,
                authorNickname: postComment?.data?.data?.authorNickname,
                authorProfileImageUrl: getUserInfo?.data?.data?.profileImageUrl,
                authorAgeGroup: getUserInfo?.data?.data?.ageGroup,
                isLiked: false,
                likeCount: 0,
                replies: [],
                createdAt: postComment?.data?.data?.createdAt,
                modifiedAt: postComment?.data?.data?.modifiedAt,
            };
            setNewCommentCnt(newCommentCnt + 1);
            setCommentList((prevComments) => {
                // 기존 댓글 목록에 newComment의 seq 값과 중복되는 댓글이 없는 경우에만 추가
                const isDuplicate = prevComments.some((comment) => comment.seq === newComment.seq);
                if (!isDuplicate) {
                    return [...prevComments, newComment];
                }
                return prevComments;
            });
        }
    }, [postComment?.status]);

    // 데이터를 가져오는 함수
    const fetchComments = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 200));

            const { data } = await axios.get(`/api/posts/${paramId}/comments?page=${currentPage}&limit=10`);

            const { totalElements } = data.pageInfo;
            setTotalCommentCnt(totalElements);
            const newComments = data.data;

            setCommentList((prevComments) => {
                // newComments에 있는 각 댓글의 seq 값과 prevComments의 seq 값을 비교하여 중복을 제거
                const uniqueComments = newComments.filter(
                    (newComment) => !prevComments.some((existingComment) => existingComment.seq === newComment.seq)
                );
                return [...prevComments, ...uniqueComments]; // 중복되지 않은 새로운 댓글만 추가
            });

            setHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching Comments:', error);
        }
    };
    // 첫 로드 시 데이터를 불러옴
    useEffect(() => {
        fetchComments(page);
    }, [page]);
    // 다음 페이지를 불러오는 함수
    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    // 스크롤 감지
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // 맨 위로 스크롤하는 함수
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //   댓글 삭제
    const deleteComment = useRequestDelete();
    const handleDeleteComment = (commentSeq) =>
        deleteComment.mutate({
            requestUrl: `/api/posts/${paramId}/comments/${commentSeq}`,
        });
    useEffect(() => {
        if (deleteComment?.status === 'success' && deleteComment?.data?.status === 200) {
            // 삭제된 댓글을 제외한 새로운 댓글 리스트를 설정
            setTotalCommentCnt(totalCommentCnt - 1);
            const deletedSeq = deleteComment?.data?.data?.seq;
            setCommentList((prevComments) => prevComments.filter((comment) => comment.seq !== deletedSeq));
        }
    }, [deleteComment.status]);
    // 댓글 수정 기능
    const [activeEdit, setActiveEdit] = useState(false);
    const patchComment = useRequestPatch();
    const handlePatchComment = (commentSeq, content) => {
        if (content) {
            patchComment.mutate({
                requestUrl: `/api/posts/${paramId}/comments/${commentSeq}`,
                patchData: {
                    content: content,
                },
            });
        }
    };
    useEffect(() => {
        if (patchComment.status === 'success') {
            const patchSeq = patchComment?.data?.data?.seq;
            const updatedContent = patchComment?.data?.data?.content;

            // commentList 의 seq 가 patchSeq 와 같은 요소의 content를 업데이트
            setCommentList((prevComments) =>
                prevComments.map((comment) =>
                    comment.seq === patchSeq ? { ...comment, content: updatedContent } : comment
                )
            );
            setActiveEdit(false);
        }
    }, [patchComment.status]);

    return (
        <div className="flex justify-center wrapper ">
            <NavBottom />

            <div className="h-full bg-white minWrapper">
                <CogenLifeMainHeader
                    isBackBtn={true}
                    designatedUrl={`/cogenLife/${paramCategory}`}
                />
                <div className="bg-white minWrapper pt-[50px] relative">
                    {showScrollUp && (
                        <div className="fixed top-10 z-20 bg-white minWrapper h-[30px] flex justify-center">
                            <button
                                type="button"
                                onClick={scrollToTop}
                            >
                                <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                            </button>
                        </div>
                    )}
                    {showFullImage && (
                        <div
                            onClick={() => setShowFullImage('')}
                            className="fixed inset-0 z-50 bg-[#00000080] flex justify-center items-center top-0"
                        >
                            <img
                                src={showFullImage}
                                alt={'preview'}
                                className=" max-w-[90%] p-4 z-[-1] "
                            />
                        </div>
                    )}
                    {getQuestionLifeId?.data?.data?.images?.length > 0 && (
                        <div className="select-none">
                            <div className="absolute top-16 right-4 z-10 w-[50px] h-[37px] flex justify-center items-center text-white bg-[#00000090] rounded-full">
                                {currentSlide}/{getQuestionLifeId?.data?.data?.images?.length}
                            </div>
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                scrollbar={{ draggable: true }}
                                onSlideChange={(swiper) =>
                                    setCurrentSlide(swiper.activeIndex > 2 ? 1 : swiper.activeIndex + 1)
                                }
                                // onSwiper={(swiper) => console.log(swiper)}
                            >
                                {sortedImages?.map((el, idx) => (
                                    <SwiperSlide key={idx}>
                                        <div
                                            onClick={() => setShowFullImage(el.s3Url)}
                                            className="w-full h-[250px] relative"
                                        >
                                            <img
                                                src={el.s3Url}
                                                alt={idx}
                                                className="object-cover w-full max-h-[250px] absolute z-[-1] "
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                </div>

                <div className="pt-[15px]">
                    {getQuestionLifeId?.data?.data && (
                        <div>
                            <AnswerCard
                                {...getQuestionLifeId?.data?.data}
                                refetchFunc={getQuestionLifeId.refetch}
                            />
                        </div>
                    )}
                    <div className="flex justify-between px-7">
                        <SaveButton
                            isActive={isBookmarks}
                            activeFunc={handlePatchBookmarks}
                        />
                        <ShareButton
                            title={getQuestionLifeId?.data?.data?.title}
                            content={getQuestionLifeId?.data?.data?.content}
                        />
                    </div>
                    <div className="mx-4 mt-4">
                        <CommentInput
                            value={comment}
                            setValue={setComment}
                            activeFunc={handlePostComment}
                            btnTitle={'작성'}
                        />
                    </div>
                </div>

                <div>
                    <div className="flex items-center mx-6 mt-7 text-text-gray">
                        <HiMiniChatBubbleOvalLeft className="text-gray-lGray h-[20px]" />
                        <div className="mx-1">댓글</div>
                        <div>
                            {/* {totalCommentCnt
                ? totalCommentCnt + newCommentCnt
                : 0 + newCommentCnt} */}
                            {(() => {
                                // 대댓글(reply) 갯수 합산
                                const replyCount = commentList.reduce(
                                    (totalReplies, comment) => totalReplies + (comment.replies?.length || 0),
                                    0
                                );

                                // totalCommentCnt와 newCommentCnt에 replyCount를 더해서 표시
                                return totalCommentCnt
                                    ? totalCommentCnt + newCommentCnt + replyCount
                                    : 0 + newCommentCnt + replyCount;
                            })()}
                        </div>
                    </div>

                    <div className="mt-3">
                        <InfiniteScroll
                            dataLength={commentList.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            endMessage={
                                <>
                                    {commentList?.length > 0 && (
                                        <p className="py-3 text-center text-gray-gray">모든 댓글을 불러왔습니다.</p>
                                    )}
                                </>
                            }
                        >
                            {commentList.length > 0 ? (
                                commentList?.map((el, idx) => (
                                    <ConyCommentCard
                                        key={idx}
                                        {...el}
                                        bgColor={idx % 2 ? 'bg-[#ffffff]' : 'bg-[#F7F9FB]'}
                                        userNickname={getUserInfo?.data?.data?.nickname}
                                        userProfileImageUrl={getUserInfo?.data?.data?.profileImageUrl}
                                        userAgeGroup={getUserInfo?.data?.data?.ageGroup}
                                        deleteFunc={handleDeleteComment}
                                        patchFunc={handlePatchComment}
                                        activeEdit={activeEdit}
                                        setActiveEdit={setActiveEdit}
                                        commentList={commentList}
                                        setCommentList={setCommentList}
                                        isCommentWriteToggle={isCommentWriteToggle}
                                        setIsCommentWriteToggle={setIsCommentWriteToggle}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
                <div className="h-[77px]"></div>
            </div>
        </div>
    );
};

export default CogenLifeCategoryId;
