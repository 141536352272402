// hooks
import { useNavigate } from 'react-router-dom';
import { useRequestGet } from 'hooks/useRequest';
// commons
import NavBottom from 'components/Nav/NavBottom';
import CategoryTab from 'pages/cogenLife/components/CateogoryTab';
import CogenLifeMainHeader from 'components/headers/CogenLifeMainHeader';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
// modals
import WritePostModal from 'components/modals/WritePostModal';
const CogenLife = () => {
    const navigate = useNavigate();
    const getMostLikedConyQuestion = useRequestGet({
        requestUrl: '/api/posts/top-liked',
        queryKey: 'getMostLikedConyQuestion',
    });

    const getRecentConyQuestion = useRequestGet({
        requestUrl: '/api/posts/latest',
        queryKey: 'getRecentConyQuestion',
    });
    return (
        <div className="wrapper h-auto max-h-[100vh] ">
            <div className="flex justify-center">
                <NavBottom />
                <div className="relative h-full bg-white minWrapper">
                    <CogenLifeMainHeader />
                    <div className="bg-white minWrapper mt-[60px] mb-[145px]">
                        <CategoryTab />
                        <div className="mt-[15px]">
                            <div className="text-[18px] font-bold mx-[22px]  mt-[14px]  mb-[10px]">🔥인기 게시글</div>
                            {getMostLikedConyQuestion?.data?.data && (
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/cogenLife/${getMostLikedConyQuestion?.data?.data.postType}/${getMostLikedConyQuestion?.data?.data.seq}`
                                        )
                                    }
                                >
                                    <PostCard {...getMostLikedConyQuestion?.data?.data} />
                                </div>
                            )}
                            <div className="text-[18px] font-bold mx-[22px]  mt-[14px]  mb-[10px]">⭐최근 게시글</div>
                            {getRecentConyQuestion?.data?.data ? (
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/cogenLife/${getRecentConyQuestion?.data?.data.postType}/${getRecentConyQuestion?.data?.data.seq}`
                                        )
                                    }
                                >
                                    <PostCard {...getRecentConyQuestion?.data?.data} />
                                </div>
                            ) : (
                                <div className="mt-[100px]">
                                    <EmptyCard title="최신글이 존재하지 않습니다" />
                                </div>
                            )}
                            {/* <PostCard />
              <PostCard />
              <PostCard /> */}
                        </div>
                    </div>
                </div>

                <WritePostModal />
            </div>
        </div>
    );
};

export default CogenLife;
