// hooks
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// images
import life from "assets/images/congenLife/life.png";
import story from "assets/images/congenLife/story.png";
import pride from "assets/images/congenLife/pride.png";
import etc from "assets/images/congenLife/etc.png";

import food from "assets/images/congenLife/food.png";
import cafe from "assets/images/congenLife/cafe.png";
import hobby from "assets/images/congenLife/hobby.png";
import travel from "assets/images/congenLife/travel.png";

import beauty from "assets/images/congenLife/beauty.png";
import health from "assets/images/congenLife/health.png";
import pet from "assets/images/congenLife/pet.png";
import edu from "assets/images/congenLife/edu.png";
// utils
import { postCategoryMathcnerByKor } from "utils/matcher";
const WritePostModal = () => {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const categoryTabData = [
    { title: "일상잡담", image: life },
    { title: "고민사연", image: story },
    { title: "깨알자랑", image: pride },
    { title: "기타등등", image: etc },

    { title: "맛집탐방", image: food },
    { title: "카페간식", image: cafe },
    { title: "취미생활", image: hobby },
    { title: "여행투어", image: travel },

    { title: "미용뷰티", image: beauty },
    { title: "운동건강", image: health },
    { title: "반려동물", image: pet },
    { title: "교육공부", image: edu },
  ];
  return (
    <div className="fixed bottom-0 z-20 minWrapper">
      {isShow && (
        <div
          className="fixed bg-[#00000040] inset-0 z-30 flex justify-center"
          onClick={(e) => {
            e.stopPropagation();
            setIsShow(false);
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white w-full h-[325px] p-5 rounded-t-[20px] absolute bottom-0 z-10 minWrapper"
          >
            <div className="text-[18px] font-bold">
              게시글 카테고리를 선택해주세요
            </div>
            <div className="grid grid-cols-4 mt-4">
              {categoryTabData.map((el, idx) => (
                <div
                  key={idx}
                  className="flex flex-col justify-center items-center w-[86px] h-[80px] active:border active:border-main-red active:rounded-[15px] hover:border hover:border-main-red hover:rounded-[15px] m-auto"
                  onClick={() =>
                    navigate(
                      `/cogenLife/post?category=${postCategoryMathcnerByKor(
                        el.title
                      )}`
                    )
                  }
                >
                  <img src={el.image} className="w-[35px]" alt={el.title} />
                  <div className="mt-1 text-[14px]">{el.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <button
        type="button"
        onClick={() => setIsShow(!isShow)}
        className="bg-main-purple h-[50px] w-[96px] rounded-full text-[16px] font-bold z-0 text-white absolute right-[7px] bottom-[87px]"
      >
        글쓰기
      </button>
    </div>
  );
};

export default WritePostModal;
