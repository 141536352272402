// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestGet, useRequestPost } from 'hooks/useRequest';
// common
import BackHeader from 'components/headers/BackHeader';
import VerfyInput from 'components/inputs/VerifyInput';
import TwoColRedTags from 'components/tags/TwoColRedTag';
import FourColBlackTag from 'components/tags/FourColBlackTag';
import { toast, ToastContainer } from 'react-toastify'; // Toastify import

const SignUp = () => {
    const navigate = useNavigate();

    const [phone, setPhone] = useState('');
    const [nickname, setNickname] = useState('');
    const [certificateNum, setCertificateNum] = useState('');

    const [pw, setPW] = useState('');
    const [isValidPW, setIsValidPW] = useState('');
    const [isMatchPW, setIsMatchPW] = useState('');
    const [certificatePW, setCertificatePW] = useState('');
    const [gender, setGender] = useState(null);
    const [age, setAge] = useState(null);

    const [fixedPhone, setFixedPhone] = useState(false);
    const [verifyCertificateNum, setVerifyCertificateNum] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [verifyNickname, setVerifyNickname] = useState(false);
    const [verifyPW, setVerifyPW] = useState(false);
    const [verifyGender, setVerifyGender] = useState(false);
    const [verifyAge, setVerifyAge] = useState(false);

    const [step, setStep] = useState(0);
    const [complete, setComplete] = useState(false);

    // 휴대폰 번호 유효성 검사 함수
    const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^010-\d{4}-\d{4}$/;
        return phoneRegex.test(phoneNumber);
    };
    const postCertificateNum = useRequestPost();
    const handleSubmitPhone = () => {
        if (!isValidPhoneNumber(phone)) {
            toast.error('유효한 휴대전화 번호를 입력하세요.', {
                position: 'top-center',
                autoClose: 500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }
        postCertificateNum.mutate({
            requestUrl: '/api/sms/send',
            postData: { phoneNumber: phone.replace(/-/g, '') },
        });
        toast.success('인증번호를 전송하였습니다.', {
            position: 'top-center',
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setFixedPhone(phone);
        setCertificateNum('');

        if (step <= 0) {
            setStep(1);
        }
    };

    // SMS
    useEffect(() => {
        // 인증번호 여부
        if (certificateNum.length === 6) {
            handleSubmitCertificateNum();
        } else if (certificateNum.length <= 0) {
            setVerifyCertificateNum(false);
        } else {
            setVerifyCertificateNum({
                state: 'error',
                message: '인증번호가 일치하지 않습니다.',
            });
            setIsVerified(false);
        }
    }, [certificateNum]);
    const postMatchCertificateNum = useRequestPost();
    const handleSubmitCertificateNum = () => {
        postMatchCertificateNum.mutate({
            requestUrl: '/api/sms/verify',
            postData: {
                phoneNumber: phone.replace(/-/g, ''),
                certificationNumber: certificateNum,
            },
        });
    };
    useEffect(() => {
        if (postMatchCertificateNum?.status === 'success') {
            if (postMatchCertificateNum?.data?.status === 200) {
                setVerifyCertificateNum({
                    state: 'success',
                    message: '인증성공했습니다.',
                });
                setIsVerified(true);
            } else {
                setVerifyCertificateNum({
                    state: 'error',
                    message: '인증번호가 일치하지 않습니다.',
                });
            }
        } else {
            setIsVerified(false);
        }
        if (step === 1) setStep(2);
    }, [postMatchCertificateNum.status]);

    // nickname
    useEffect(() => {
        if (!nickname) setVerifyNickname(false);
        if (nickname)
            setVerifyNickname({
                state: 'warning',
                message: '중복확인이 필요합니다.',
            });
    }, [nickname]);
    const postVerifyNickname = useRequestPost();
    const handleVerifyNickname = () => {
        postVerifyNickname.mutate({
            requestUrl: '/api/users/check-nickname',
            postData: {
                nickname: nickname,
            },
        });
    };
    const handleSubmitNickname = () => {
        const nicknameRegex = /^[a-zA-Z0-9_\-!@#$%^&*()\uAC00-\uD7A3]{2,9}$/;
        if (nicknameRegex.test(nickname)) {
            handleVerifyNickname();
        } else if (nickname.length <= 0) {
            setVerifyNickname(false);
        } else {
            if (nickname.length < 2 || nickname.length > 9) {
                setVerifyNickname({
                    state: 'error',
                    message: '2글자 이상 10글자 미만의 닉네임을 입력하세요',
                });
            } else {
                setVerifyNickname({
                    state: 'error',
                    message: '닉네임에 잘못된 문자가 포함되었습니다',
                });
            }
        }
    };
    useEffect(() => {
        if (postVerifyNickname.status === 'success' && postVerifyNickname?.data?.data?.isAvailable) {
            setVerifyNickname({
                state: 'success',
                message: '사용가능한 닉네임입니다.',
            });
            if (step === 2) setStep(3);
        } else if (postVerifyNickname?.status === 'success' && !postVerifyNickname?.data?.data?.isAvailable) {
            setVerifyNickname({
                state: 'error',
                message: '중복된 닉네임이 있습니다.',
            });
        }
    }, [postVerifyNickname.status]);

    // pw
    useEffect(() => {
        const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
        if (pwRegex.test(pw)) {
            if (step === 3) setStep(4);
            setIsValidPW(true);
            // 결국엔 true가 되어야지.. 2024.09.09 01시 수정
        } else if (pw.length <= 0) setIsValidPW(false);
        else {
            setIsValidPW({
                state: 'error',
                message: '문자와 숫자를 포함하여 8자 이상 15자 이하로 입력하세요.',
            });
        }
    }, [pw]);

    useEffect(() => {
        if (certificatePW) {
            setIsMatchPW({
                state: 'error',
                message: '비밀번호가 일치하지 않습니다.',
            });
        }
    }, [certificatePW]);

    useEffect(() => {
        if (certificatePW && certificatePW === pw) {
            setVerifyPW(true);
            setIsMatchPW(true);
            if (step === 4) setStep(5);
        } else {
            setVerifyPW(false);
        }
    }, [certificatePW, pw]);

    useEffect(() => {
        if (gender) setVerifyGender(true);
        if (step === 5) setStep(6);
    }, [gender]);

    useEffect(() => {
        if (age) setVerifyAge(true);
        if (step === 6) setStep(7);
    }, [age]);

    //   회원가입
    const postSignUp = useRequestPost();
    useEffect(() => {
        const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
        if (
            phone === fixedPhone &&
            verifyCertificateNum.state === 'success' &&
            verifyNickname.state === 'success' &&
            pwRegex.test(pw) &&
            verifyPW &&
            isValidPW &&
            isValidPW?.state !== 'error' &&
            verifyGender &&
            verifyAge
        )
            setComplete(true);
        else setComplete(false);
    }, [phone, fixedPhone, verifyCertificateNum, pw, isValidPW, verifyNickname, verifyPW, verifyGender, verifyAge]);
    const handlePostSignUp = () => {
        if (complete) {
            postSignUp.mutate({
                requestUrl: '/api/users/register',
                postData: {
                    phoneNumber: phone.replace(/-/g, ''),
                    nickname: nickname,
                    password: pw,
                    passwordRepeat: pw,
                    ageGroup: age,
                    gender: gender,
                    isVerified: isVerified,
                },
            });
        }
    };
    useEffect(() => {
        if (postSignUp.status === 'success') {
            if (postSignUp.data?.status === 201) {
                // navigate('/signIn');
                toast.success('회원가입 되었습니다', {
                    position: 'top-center',
                    autoClose: 800,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: true,
                    onClose: () => navigate('/signIn'), // 페이지 이동
                    onClick: () => navigate('/signIn'), // 클릭 시 페이지 이동
                });
            } else {
                toast.error(postSignUp?.data?.response?.data?.message || '정확한 정보를 확인하세요.', {
                    position: 'top-center',
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } else if (postSignUp.data) {
            setIsValidPW({
                state: 'error',
                message: postSignUp.data.data.message,
            });
        }
    }, [postSignUp.status]);
    return (
        <div className="flex justify-center h-auto max-h-[100vh] wrapper mt-[40px] mb-[100px]">
            <div className=" minWrapper">
                <BackHeader designatedUrl={'/signIn'} />
                <ToastContainer />

                <div className="mx-[22px] mt-5 ">
                    <div className="text-[16px] font-bold mb-3">회원정보</div>
                    <VerfyInput
                        value={phone}
                        setValue={setPhone}
                        placeholder="휴대전화번호"
                        type="grayType"
                        inputType="phone"
                        verifyBtnTitle="인증번호"
                        onClickFunc={handleSubmitPhone}
                    />
                    <div className="my-2"></div>
                    {step >= 1 && (
                        <VerfyInput
                            value={certificateNum}
                            setValue={setCertificateNum}
                            placeholder="인증번호"
                            state={verifyCertificateNum?.state}
                            stateMessage={verifyCertificateNum?.message}
                            type="grayType"
                        />
                    )}

                    <div className="my-2"></div>
                    {step >= 2 && (
                        <VerfyInput
                            value={nickname}
                            setValue={setNickname}
                            placeholder="닉네임"
                            type="grayType"
                            verifyBtnTitle="중복확인"
                            resetBtn={true}
                            state={verifyNickname?.state}
                            stateMessage={verifyNickname?.message}
                            onClickFunc={handleSubmitNickname}
                        />
                    )}

                    <div className="my-2"></div>
                    {step >= 3 && (
                        <VerfyInput
                            value={pw}
                            setValue={setPW}
                            placeholder="비밀번호"
                            type="grayType"
                            inputType="password"
                            state={isValidPW?.state}
                            stateMessage={isValidPW?.message}
                        />
                    )}
                    <div className="my-2"></div>

                    {step >= 4 && (
                        <VerfyInput
                            value={certificatePW}
                            setValue={setCertificatePW}
                            placeholder="비밀번호 확인"
                            type="grayType"
                            inputType="password"
                            maxLength={15}
                            autoFocus={false}
                            state={isMatchPW?.state}
                            stateMessage={isMatchPW?.message}
                        />
                    )}
                </div>

                {step >= 5 && (
                    <div className="mx-[22px] mt-4">
                        <div className="mb-3">성별</div>
                        <TwoColRedTags
                            selectedTag={gender}
                            setSelectedTag={setGender}
                            tags={[
                                { gender: 'MALE', genderKR: '남성' },
                                { gender: 'FEMALE', genderKR: '여성' },
                            ]}
                            mainKey="gender"
                            viewKey="genderKR"
                        />
                    </div>
                )}

                {step >= 6 && (
                    <div className="mx-[22px] mt-4">
                        <div className="mb-3">연령대</div>
                        <FourColBlackTag
                            selectedTag={age}
                            setSelectedTag={setAge}
                            tags={[
                                { ageGroup: 'TEENS', ageKR: '10대' },
                                { ageGroup: 'TWENTIES', ageKR: '20대' },
                                { ageGroup: 'THIRTIES', ageKR: '30대' },
                                { ageGroup: 'FORTIES', ageKR: '40대' },
                                { ageGroup: 'FIFTIES', ageKR: '50대' },
                                { ageGroup: 'SIXTIES', ageKR: '60대' },
                                { ageGroup: 'SEVENTIES', ageKR: '70대' },
                                { ageGroup: 'EIGHTIES_AND_ABOVE', ageKR: '80대' },
                            ]}
                            mainKey="ageGroup"
                            viewKey="ageKR"
                        />
                    </div>
                )}

                {complete && (
                    <div className="pb-24 text-center pt-7">
                        <div className="p-3 m-4 text-sm rounded-lg bg-back-white text-gray-gray">
                            <div>로그인하면 아래 정책에 모두 동의하게 됩니다</div>
                            <div className="flex justify-center mt-2 text-xs">
                                <div className="mr-3 text-blue-700 border-b border-blue-800">이용약관</div>
                                <div className="mr-3 text-blue-700 border-b border-blue-800">개인정보처리방침</div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="fixed bottom-0 w-full px-2 py-3 bg-white border-t border-gray-sGray minWrapper">
                    <button
                        type="button"
                        onClick={() => handlePostSignUp()}
                        className={
                            complete
                                ? 'w-full p-3 font-bold rounded-full bg-main-red text-white'
                                : 'w-full p-3 font-bold rounded-full bg-status-disabled text-text-gray'
                        }
                    >
                        회원가입
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
