// hooks
import { useNavigate } from "react-router-dom";
import { useRequestGet } from "hooks/useRequest";
// icons
import { IoIosArrowForward } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
// commons
import BackHeader from "components/headers/BackHeader";
import NavBottom from "components/Nav/NavBottom";
// utils
import { genderMatcherByEng, ageGroupMatcherByEng } from "utils/matcher";
const MyCogen = () => {
  const navigate = useNavigate();
  let getUserInfo = useRequestGet({
    requestUrl: "/api/users/profile",
    queryKey: "userInfo",
  });
  const handleLogout = () => {
    localStorage.removeItem("TOKEN"); // 로컬스토리지에서 TOKEN 삭제
    navigate("/signIn");
    window.location.reload();
  };
  return (
    <div className="wrapper">
      <div className="relative flex justify-center">
        <BackHeader
          isBackshow={false}
          title="나의 코젠"
          isAlarmShow={true}
          designatedUrl={"/home"}
        />
        <NavBottom />
        <div className="bg-white minWrapper pt-[60px] ">
          <div className="bg-back-white p-[22px] h-[195px] items-center flex w-full">
            <div className="w-full">
              <div className="flex">
                {getUserInfo?.data?.data &&
                getUserInfo?.data?.data?.profileImageUrl ? (
                  <img
                    src={getUserInfo?.data?.data?.profileImageUrl}
                    alt="profile"
                    className="w-[75px] h-[75px] border border-[#cdcdcdcd] rounded-full"
                  />
                ) : (
                  <div className="w-[75px] h-[75px] border border-[#cdcdcdcd]  rounded-full">
                    <FaUserCircle className="w-full h-full text-gray-lGray" />
                  </div>
                )}
                <div className="h-[50px] ml-5 my-auto">
                  <div className="font-semibold text-[20px]">
                    {getUserInfo?.data?.data?.nickname}
                  </div>
                  <div className="text-text-dGray text-[14px]">
                    {`${ageGroupMatcherByEng(
                      getUserInfo?.data?.data?.ageGroup
                    )}, ${genderMatcherByEng(getUserInfo?.data?.data?.gender)}`}
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => navigate("/myCogen/editInfo")}
                className="mt-5 border font-bold border-gray-lGray bg-white text-gray-gray w-full h-[50px] rounded-full flex justify-center items-center"
              >
                회원 정보 수정
              </button>
            </div>
          </div>

          <div className="mt-[22px]">
            <button
              type="button"
              onClick={() => navigate("/myCogen/storage?type=mySave")}
              className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
            >
              <div>내가 저장한 글</div>
              <div className="w-[50px] h-[50px] flex justify-center items-center">
                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
              </div>
            </button>
            <button
              type="button"
              onClick={() => navigate("/myCogen/storage?type=myPost")}
              className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
            >
              <div>나의 게시글</div>
              <div className="w-[50px] h-[50px] flex justify-center items-center">
                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
              </div>
            </button>
            <button
              type="button"
              onClick={() => navigate("/myCogen/comment")}
              className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
            >
              <div>나의 댓글</div>
              <div className="w-[50px] h-[50px] flex justify-center items-center">
                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
              </div>
            </button>
            <button
              type="button"
              onClick={() => handleLogout()}
              className="h-[50px] text-[18px] text-text-lGray pl-[22px] flex justify-between items-center"
            >
              <div>로그아웃</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCogen;
