// hooks
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// api
import axios from "api/axios";

// GET 요청을 보낼 함수 정의
export const useRequestGet = ({ requestUrl, queryKey }) => {
  // console.log("Get요청");

  const getQuery = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        const result = await axios.get(`${requestUrl}`);
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    enabled: !!requestUrl, // requestUrl이 존재할 때만 쿼리가 활성화되도록 설정
  });
  return getQuery;
};

// POST 요청을 보낼 함수 정의
export const useRequestPost = () => {
  // console.log("Post요청");
  const mutation = useMutation({
    mutationFn: async ({ requestUrl, postData }) => {
      try {
        return await axios.post(`${requestUrl}`, postData);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  });

  return mutation;
};

// Patch 요청을 보낼 함수 정의
export const useRequestPatch = () => {
  // console.log("Post요청");
  const mutation = useMutation({
    mutationFn: async ({ requestUrl, patchData }) => {
      try {
        return await axios.patch(`${requestUrl}`, patchData);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  });

  return mutation;
};

export const useRequestDelete = () => {
  // console.log("Post요청");
  const mutation = useMutation({
    mutationFn: async ({ requestUrl, deleteData }) => {
      try {
        return await axios.delete(`${requestUrl}`, deleteData);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  });

  return mutation;
};

// form data POST 요청
// POST 요청을 보낼 함수 정의
export const useFormDataPost = () => {
  // console.log("Post요청");
  const mutation = useMutation({
    mutationFn: async ({ requestUrl, postData }) => {
      try {
        return await axios.post(`${requestUrl}`, postData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        // console.log(error);
        return error;
      }
    },
  });

  return mutation;
};
