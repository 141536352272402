// hooks
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// icons
import { TbBellFilled } from "react-icons/tb";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
// utils
import { postCategoryMathcnerByEng } from "utils/matcher";
// common
import AlarmIcon from "components/headers/AlarmIcon";

const CogenLifeMainHeader = ({ isBackBtn = false, designatedUrl = "" }) => {
  const navigate = useNavigate();
  const paramsCategory = useParams()?.category;
  return (
    <div className="h-[50px] flex justify-between items-center fixed minWrapper z-20 bg-white">
      <div className="flex items-center">
        {isBackBtn && (
          <div className="w-[50px] h-[50px] flex items-center justify-center">
            <IoIosArrowBack
              onClick={() =>
                designatedUrl ? navigate(designatedUrl) : navigate(-1)
              }
              className="w-7 h-7"
            />
          </div>
        )}
        <div
          className={
            paramsCategory
              ? "text-[20px] font-bold"
              : "text-[20px] font-bold pl-4"
          }
        >
          {paramsCategory
            ? postCategoryMathcnerByEng(paramsCategory)
            : "코젠생활"}
        </div>
      </div>
      <div className="flex items-center ">
        <div
          className="relative w-[50px] h-[50px] flex justify-center items-center"
          onClick={() => navigate("/cogenLife/search")}
        >
          <IoSearch className="w-[27px] h-[27px] text-[#ACB5BD]" />
        </div>
        <AlarmIcon />
      </div>
    </div>
  );
};

export default CogenLifeMainHeader;
