// hooks
import { useRequestGet, useRequestPost } from "hooks/useRequest";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
// commons
import ConyCommentDetailCard from "components/cards/ConyCommentDetailCard";
import ConnyCommentReplyCard from "components/cards/ConyCommentReplyCard";
import CommentInput from "components/inputs/CommentInput";
import BackHeader from "components/headers/BackHeader";
import NavBottom from "components/Nav/NavBottom";
import ShareButton from "components/buttons/ShareButton";
// icons
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";

const ConyQuestionComment = () => {
  const location = useLocation();
  const props = location.state;
  const queryParams = new URLSearchParams(location.search);
  const commentIdx = queryParams.get("commentIdx"); // "" 값 가져오기
  const questionSeq = useParams().questionSeq;
  const commentSeq = useParams().commentSeq;
  const [parentComment, setParentComment] = useState();
  const [commentContent, setCommentContent] = useState("");
  const [replies, setReplies] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [newComment, setNewComment] = useState("");
  const getQuestion = useRequestGet({
    requestUrl: `/api/questions/${questionSeq}`,
    queryKey: `getLastQuestion-${questionSeq}`,
  });
  const getAllComments = useRequestGet({
    requestUrl: `/api/questions/${questionSeq}/comments?page=${
      Number(commentIdx) + 1
    }&limit=1`, // 페이지네이션 활용하여 idx 값 하나만 불러오기
    queryKey: `getAllComments-${commentSeq}-${commentIdx}`,
  });

  // console.log("commentIdx", commentIdx);

  // useEffect(()=>{if(getAllComments?.status==='success'){
  //     const foundComment = getAllComments?.data?.data?.replies?.find(reply => reply.seq === commentSeq);
  //     setCommentContent()
  //     setReplies()
  //     setIsLiked()
  //     setLikeCount()
  //     setNewComment()

  // }},[getAllComments?.status])
  const getUserInfo = useRequestGet({
    requestUrl: "/api/users/profile",
    queryKey: "getUserInfo",
  });
  const postNewComment = useRequestPost();
  const handlePostNewComment = () => {
    if (newComment) {
      postNewComment.mutate({
        requestUrl: `/api/questions/${questionSeq}/comments/${commentSeq}/reply`,
        postData: {
          content: newComment,
        },
      });
    }
  };
  useEffect(() => {
    if (postNewComment?.status === "success") {
      setNewComment("");
      const newComment = {
        seq: postNewComment?.data?.data?.seq,
        content: postNewComment?.data?.data?.content,
        authorNickname: postNewComment?.data?.data?.authorNickname,
        authorProfileImageUrl: getUserInfo?.data?.data?.profileImageUrl,
        authorAgeGroup: getUserInfo?.data?.data?.ageGroup,
        createdAt: postNewComment?.data?.data?.createdAt,
        modifiedAt: postNewComment?.data?.data?.modifiedAt,
      };
      // setNewCommentCnt(newCommentCnt + 1);
      setReplies((prevComments) => {
        // 기존 댓글 목록에 newComment의 seq 값과 중복되는 댓글이 없는 경우에만 추가
        const isDuplicate = prevComments.some(
          (comment) => comment.seq === newComment.seq
        );
        if (!isDuplicate) {
          return [...prevComments, newComment];
        }
        return prevComments;
      });
    }
  }, [postNewComment?.status]);

  useEffect(() => {
    if (getAllComments?.data) {
      const curComment = getAllComments?.data?.data?.data.find(
        (comment) => comment.seq === Number(commentSeq)
      );

      if (curComment) {
        setParentComment(curComment);
        setCommentContent(curComment.content);
        setReplies(curComment.replies);
        setIsLiked(curComment.isLiked);
        setLikeCount(curComment.likeCount);
      }
    }
  }, [getAllComments?.data]);

  return (
    <div className="wrapper">
      <div className="flex justify-center h-full">
        <BackHeader
          isBackshow={false}
          title="코니질문"
          isAlarmShow={true}
          designatedUrl={
            props?.designatedActivQuestioneUrl
              ? props?.designatedActivQuestioneUrl
              : `/conyQuestion/all/${questionSeq}`
          }
        />
        <NavBottom />

        <div className="bg-white minWrapper pt-[60px] mb-[90px]">
          {parentComment && (
            <ConyCommentDetailCard
              {...parentComment}
              questionSeq={questionSeq}
              content={commentContent}
              replies={replies}
              isLiked={isLiked}
              likeCount={likeCount}
              userNickname={getUserInfo?.data?.data?.nickname}
              refetchFunc={getAllComments.refetch}
            />
          )}

          {/* 공유하기 */}
          <div className="px-[30px] h-[40px]">
            <ShareButton
              title={getQuestion?.data?.data?.title}
              content={commentContent}
            />
          </div>

          {/* 대댓글 작성 */}
          <div className="mx-[20px]">
            <CommentInput
              value={newComment}
              setValue={setNewComment}
              activeFunc={handlePostNewComment}
              btnTitle={"작성"}
            />
          </div>
          <div className="flex items-center mx-[24px] mt-[16px] text-text-gray text-[14px]">
            <HiMiniChatBubbleOvalLeft className="text-gray-lGray h-[14px] w-[14px]" />
            <div className="mx-1">댓글</div>
            <div>{replies?.length || 0}</div>
          </div>
          {/* 대댓글 */}
          <div className="">
            {replies.map((el, idx) => (
              <ConnyCommentReplyCard
                key={el.seq} // 인덱스 대신 고유한 seq 값을 key로 사용
                {...el}
                userNickname={getUserInfo?.data?.data.nickname}
                questionSeq={questionSeq}
                refetchFunc={getAllComments.refetch}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConyQuestionComment;
