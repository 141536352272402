// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequestPost } from "hooks/useRequest";
import { useRequestAuth } from "hooks/useAuth";

// common
import DefaultInput from "components/inputs/DefaultInput";
import DefaultButton from "components/buttons/DefaultButton";
import { toast, ToastContainer } from "react-toastify"; // Toastify import
// images
import cogen_symbol_light from "assets/images/brand/cogen_symbol_light.png";
import cogen_wordmark_white from "assets/images/brand/cogen_wordmark_white.png";

const SignIn = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [pw, setPW] = useState("");
  const postSignIn = useRequestPost();
  const handlePostSignIn = () => {
    if (!phone) {
      toast.error("휴대폰번호를 입력하세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (!pw) {
      toast.error("비밀번호를 입력하세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      postSignIn.mutate({
        requestUrl: "/api/login",
        postData: {
          phoneNumber: phone.replace(/-/g, ""),
          password: pw,
        },
      });
    }
  };
  useEffect(() => {
    if (postSignIn.status === "success") {
      if (postSignIn?.data?.status === 200) {
        const TOKEN = postSignIn?.data?.headers["authorization"];
        // 1. 로컬 스토리지에 토큰 저장
        localStorage.setItem("TOKEN", TOKEN);

        window.setTimeout(() => {
          navigate("/home", { replace: true });
        }, 300); // 새로고침 전에 약간의 지연을 추가
      } else {
        toast.error(
          postSignIn?.data?.response?.data?.message[0].isArray
            ? postSignIn?.data?.response?.data?.message[0]
            : postSignIn?.data?.response?.data?.message ||
                "id,pw를 확인하세요.",
          {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    }
  }, [postSignIn.status]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handlePostSignIn();
    }
  };
  return (
    <div className="fixed h-screen wrapper bg-main-red">
      <ToastContainer />

      <div className="flex justify-center h-full ">
        <div className="minWrapper bg-main-red">
          <div className="flex justify-end mt-10">
            <img
              src={cogen_symbol_light}
              alt="cogen_symbol"
              className="h-[145px]"
            />
          </div>
          <div className="relative flex justify-center w-full">
            <img
              src={cogen_wordmark_white}
              alt="cogen_wordmark_white"
              className="w-[189px] mt-[-20px] absolute"
            />
          </div>

          <div
            className="mx-5 mt-24 text-white"
            onKeyDown={handleKeyDown}
            // tabIndex={0}
          >
            <DefaultInput
              value={phone}
              setValue={setPhone}
              type="whiteBorderType"
              placeholder="휴대전화 번호"
              inputType="phone"
              resetBtn={true}
            />
            <div className="my-2"></div>
            <DefaultInput
              value={pw}
              setValue={setPW}
              type="transparentWhiteType"
              placeholder="비밀번호"
              inputType="password"
            />
            <div className="my-4"></div>
            <DefaultButton
              onClickFunc={() => handlePostSignIn()}
              title="로그인"
              type="whiteType"
            />
            <div className="my-2"></div>
            <DefaultButton
              onClickFunc={() => navigate("/signUp")}
              title="회원가입"
              type="redType"
            />
            <div className="my-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
