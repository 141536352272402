// hooks
import { useNavigate } from 'react-router-dom';
// images
import life from 'assets/images/congenLife/life.png';
import story from 'assets/images/congenLife/story.png';
import pride from 'assets/images/congenLife/pride.png';
import etc from 'assets/images/congenLife/etc.png';

import food from 'assets/images/congenLife/food.png';
import cafe from 'assets/images/congenLife/cafe.png';
import hobby from 'assets/images/congenLife/hobby.png';
import travel from 'assets/images/congenLife/travel.png';

import beauty from 'assets/images/congenLife/beauty.png';
import health from 'assets/images/congenLife/health.png';
import pet from 'assets/images/congenLife/pet.png';
import edu from 'assets/images/congenLife/edu.png';
// utils
import { postCategoryMathcnerByKor } from 'utils/matcher';

const CategoryTab = () => {
    const navigate = useNavigate();
    const categoryTabData = [
        { title: '일상잡담', image: life },
        { title: '고민사연', image: story },
        { title: '깨알자랑', image: pride },
        { title: '기타등등', image: etc },

        { title: '맛집탐방', image: food },
        { title: '카페간식', image: cafe },
        { title: '취미생활', image: hobby },
        { title: '여행투어', image: travel },

        { title: '미용뷰티', image: beauty },
        { title: '운동건강', image: health },
        { title: '반려동물', image: pet },
        { title: '교육공부', image: edu },
    ];
    return (
        <div className="grid grid-cols-4 px-3">
            {categoryTabData.map((el, idx) => (
                <div
                    key={idx}
                    className="flex flex-col justify-center items-center  w-[86px] h-[80px] active:border active:border-main-red active:rounded-[15px] hover:border m-auto hover:border-main-red hover:rounded-[15px]"
                    onClick={() => navigate(`/cogenLife/${postCategoryMathcnerByKor(el.title)}`)}
                >
                    <img
                        src={el.image}
                        className="w-[35px]"
                        alt={el.title}
                    />
                    <div className="mt-1 text-[14px]">{el.title}</div>
                </div>
            ))}
        </div>
    );
};

export default CategoryTab;
