import { useState } from "react";

const FilterTag = ({ selectedTag, setSelectedTag, tags, mainKey, viewKey }) => {
  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };
  return (
    <div className="flex gap-[10px]">
      {tags.map((tag, idx) => (
        <button
          type="button"
          key={tag[viewKey]}
          onClick={() => handleTagClick(tag[mainKey])}
          className={`border px-[15px] h-[30px] flex justify-center items-center rounded-full text-[14px] ${
            selectedTag === tag[mainKey]
              ? "text-main-red border-main-red font-bold"
              : "text-gray-gray border-gray-lGray"
          }`}
        >
          {tag[viewKey]}
        </button>
      ))}
    </div>
  );
};

export default FilterTag;
