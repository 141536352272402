import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'api/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IoIosArrowUp } from 'react-icons/io';
// commons
import NavBottom from 'components/Nav/NavBottom';
import CogenLifeMainHeader from 'components/headers/CogenLifeMainHeader';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
import LoadingCard from 'components/cards/LoadingCard';
// modals
import WritePostModal from 'components/modals/WritePostModal';

const CogenLifeCategory = () => {
    const location = useLocation().pathname;
    const category = location.split('/')[2];
    const navigate = useNavigate();
    const [posts, setPosts] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태

    // 데이터를 가져오는 함수
    const fetchPosts = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 200));

            const { data } = await axios.get(
                `/api/posts/list/${category}?postType=${category}&page=${currentPage}&limit=10`
            );
            const newPosts = data.data;
            setPosts((prevPosts) => {
                const uniquePostList = [...(prevPosts ?? []), ...newPosts].reduce((acc, newPost) => {
                    if (!acc.find((post) => post.seq === newPost.seq)) {
                        acc.push(newPost);
                    }
                    return acc;
                }, []);
                return uniquePostList;
            });
            // setPosts((prevPosts) => [...prevPosts, ...newPosts]); // 기존 포스트에 새로 가져온 포스트 추가
            setHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    // 첫 로드 시 데이터를 불러옴
    useEffect(() => {
        fetchPosts(page);
    }, [page]);

    // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // 다음 페이지를 불러오는 함수
    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    // 맨 위로 스크롤하는 함수
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="wrapper h-auto max-h-[100vh]">
            <div className="flex justify-center">
                <NavBottom />
                <div className="h-full bg-white minWrapper">
                    <CogenLifeMainHeader
                        isBackBtn={true}
                        designatedUrl={'/cogenLife'}
                    />
                    <div className="bg-white minWrapper pt-[60px] mb-[90px]">
                        {/* 아래 아이콘은 스크롤 높이가 0이 아니고 2페이지 정도의 높이가 되었을시 보이게 하고, 아이콘을 클릭하면 맨 위로 스크롤 */}
                        {showScrollUp && (
                            <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
                                <button
                                    type="button"
                                    onClick={scrollToTop}
                                >
                                    <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                                </button>
                            </div>
                        )}

                        <div>
                            {posts?.length > 0 ? (
                                <InfiniteScroll
                                    dataLength={posts?.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    endMessage={
                                        <>
                                            {posts?.length > 0 && (
                                                <p className="pb-4 text-center text-gray-gray">
                                                    모든 게시글을 불러왔습니다.
                                                </p>
                                            )}
                                        </>
                                    }
                                >
                                    {posts.map((post, idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => navigate(`/cogenLife/${category}/${post.seq}`)}
                                        >
                                            <PostCard {...post} />
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            ) : posts ? (
                                <div className="mt-[200px]">
                                    <EmptyCard title={'게시글이 존재하지 않습니다.'} />
                                </div>
                            ) : (
                                <LoadingCard />
                            )}
                        </div>
                    </div>

                    <WritePostModal />
                </div>
            </div>
        </div>
    );
};

export default CogenLifeCategory;
