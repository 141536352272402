// hooks
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRequestGet } from 'hooks/useRequest';
import { useState, useEffect } from 'react';
import axios from 'api/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
// icons
import { IoIosArrowUp } from 'react-icons/io';
import LoadingCard from 'components/cards/LoadingCard';

const MyCogen = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type');
    const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
    // const [curBookmarkPage, setCurBookmarkPage] = useState(1);
    // const getBookmark = useRequestGet({
    //   requestUrl: `/api/posts/bookmarks?page=${curBookmarkPage}&limit=10`,
    //   queryKey: "bookmarks",
    // });
    // const getSaves = useRequestGet({
    //   requestUrl: `/api/users/my-posts?page=${curBookmarkPage}&limit=10`,
    //   queryKey: "my-posts",
    // });

    // 북마크 데이터

    const [myBookmarks, setMyBookmarks] = useState(null);
    const [bookmarkPage, setBookmarkPage] = useState(1);
    const [bookmarkHasMore, setBookmarkHasMore] = useState(true);
    const getMyBookmarks = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 100));

            const { data } = await axios.get(`/api/posts/bookmarks?page=${currentPage}&limit=10`);
            const newBookmarks = data.data;

            // 이전 데이터에 새 데이터를 추가
            setMyBookmarks((prevBookmarks) => {
                const uniqueBookmarks = [...(prevBookmarks ?? []), ...newBookmarks].reduce((acc, newBookmark) => {
                    if (!acc.find((bookmark) => bookmark.seq === newBookmark.seq)) {
                        acc.push(newBookmark);
                    }
                    return acc;
                }, []);
                return uniqueBookmarks;
            });
            setBookmarkHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    // 첫 로드 시 데이터를 불러옴
    useEffect(() => {
        if (type === 'mySave') getMyBookmarks(bookmarkPage);
    }, [bookmarkPage]);

    // 다음 페이지를 불러오는 함수
    const fetchMoreBookmarkData = () => {
        setBookmarkPage((prevPage) => prevPage + 1);
    };

    /*******************내 게시글 데이터**********************/
    const [myPosts, setMyPosts] = useState(null);
    const [postPage, setPostPage] = useState(1);
    const [postHasMore, setPostHasMore] = useState(true);
    const getMyPosts = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 100));

            const { data } = await axios.get(`/api/users/my-posts?page=${currentPage}&limit=10`);
            const newPosts = data.data;

            // 이전 데이터에 새 데이터를 추가
            setMyPosts((prevPosts) => {
                const uniquePosts = [...(prevPosts ?? []), ...newPosts].reduce((acc, newPost) => {
                    if (!acc.find((post) => post.seq === newPost.seq)) {
                        acc.push(newPost);
                    }
                    return acc;
                }, []);
                return uniquePosts;
            });
            setPostHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    // 첫 로드 시 데이터를 불러옴
    useEffect(() => {
        if (type === 'myPost') getMyPosts(postPage);
    }, [postPage]);

    // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // 다음 페이지를 불러오는 함수
    const fetchMorePostData = () => {
        setPostPage((prevPage) => prevPage + 1);
    };

    // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // 맨 위로 스크롤하는 함수

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className="wrapper h-auto max-h-[100vh] ">
            <div className="relative flex justify-center">
                <BackHeader
                    isBackshow={false}
                    title={type === 'mySave' ? '내가 저장한 글' : '나의 게시글'}
                    isAlarmShow={true}
                    designatedUrl={'/myCogen'}
                />
                <NavBottom />
                <div className="bg-white minWrapper pt-[60px] mb-[70px]">
                    {showScrollUp && (
                        <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
                            <button
                                type="button"
                                onClick={scrollToTop}
                            >
                                <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                            </button>
                        </div>
                    )}
                    {type === 'mySave' && myBookmarks ? (
                        <InfiniteScroll
                            dataLength={myBookmarks?.length}
                            next={fetchMoreBookmarkData}
                            hasMore={bookmarkHasMore}
                            endMessage={
                                <>
                                    {myBookmarks?.length > 0 && (
                                        <p className="text-center text-gray-gray pb-4">모든 북마크를 불러왔습니다.</p>
                                    )}
                                </>
                            }
                        >
                            {myBookmarks?.length > 0 ? (
                                myBookmarks.map((el, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => navigate(`/cogenLife/${el.postType}/${el.seq}`)}
                                    >
                                        <PostCard {...el} />
                                    </div>
                                ))
                            ) : (
                                <div className="mt-[200px]">
                                    <EmptyCard title={'내가 저장한 글이 존재하지 않습니다.'} />
                                </div>
                            )}
                        </InfiniteScroll>
                    ) : type === 'myPost' && myPosts ? (
                        <InfiniteScroll
                            dataLength={myPosts?.length}
                            next={fetchMorePostData}
                            hasMore={postHasMore}
                            endMessage={
                                <>
                                    {myPosts?.length > 0 && (
                                        <p className="text-center text-gray-gray pb-4">
                                            모든 내 게시글을 불러왔습니다.
                                        </p>
                                    )}
                                </>
                            }
                        >
                            {myPosts?.length > 0 ? (
                                myPosts.map((el, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => navigate(`/cogenLife/${el.postType}/${el.seq}`)}
                                    >
                                        <PostCard {...el} />
                                    </div>
                                ))
                            ) : (
                                <div className="mt-[200px]">
                                    <EmptyCard title={'나의 게시글이 존재하지 않습니다.'} />
                                </div>
                            )}
                        </InfiniteScroll>
                    ) : (
                        <LoadingCard />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyCogen;
