import { IoChatbubbleSharp } from "react-icons/io5";
import { PiExclamationMarkBold } from "react-icons/pi";

const TitleInput = ({ state = "", stateMessage = "", title, setTitle }) => {
  return (
    <>
      <input
        className={
          state === "error"
            ? "px-3 text-main-red bg-[#FFEFEF]  w-full rounded-full border border-main-red focus:outline-none h-[50px]"
            : "bg-gray-white px-3 text-text-gray w-full rounded-full focus:outline-none h-[50px]"
        }
        placeholder="제목을 입력해주세요"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        maxLength={500}
      />
      {state === "error" && (
        <div className="flex items-center mt-1">
          <div className="relative">
            <IoChatbubbleSharp className="w-4 h-4 text-status-error" />
            <PiExclamationMarkBold className="absolute top-[1px] w-3 h-3 text-white left-[2px]" />
          </div>
          <div className="ml-1 text-xs font-bold text-status-error">
            {stateMessage}
          </div>
        </div>
      )}
    </>
  );
};

export default TitleInput;
