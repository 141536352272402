// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequestGet } from "hooks/useRequest";
import axios from "api/axios";
import InfiniteScroll from "react-infinite-scroll-component";
// icons
import { IoIosArrowUp } from "react-icons/io";
// commons
import BackHeader from "components/headers/BackHeader";
import NavBottom from "components/Nav/NavBottom";
import PostCardInConyQuestion from "components/cards/PostCardInConyQuestion";
import QuestionBannerCard from "components/cards/QuestionBannerCard";
import { toast, ToastContainer } from "react-toastify"; // Toastify import
import EmptyCard from "components/cards/EmptyCard";
import LoadingCard from "components/cards/LoadingCard";
const ConyQuestion = () => {
  const navigate = useNavigate();
  const [seq, setSeq] = useState(null);

  const getConyQuestion = useRequestGet({
    requestUrl: "/api/questions/active",
    queryKey: "getConyQuestion",
  });
  useEffect(() => {
    if (getConyQuestion?.data?.data) {
      const fetchedSeq = getConyQuestion?.data?.data?.seq;
      setSeq(fetchedSeq);
    }
  }, [getConyQuestion?.data?.data]);

  const [conyQuestionComment, setConyQuestionComment] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
  // 데이터를 가져오는 함수
  const getConyQuestionComment = async (currentPage) => {
    try {
      // 1초 딜레이를 추가
      await new Promise((resolve) => setTimeout(resolve, 200));

      const { data } = await axios.get(
        `/api/questions/${seq}/comments?page=${currentPage}&limit=10`
      );
      const newComment = data.data;

      setConyQuestionComment((prevPosts) => [
        ...(prevPosts ?? []),
        ...newComment,
      ]);
      setHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  // 첫 로드 시 데이터를 불러옴
  useEffect(() => {
    if (seq) {
      getConyQuestionComment(page);
    }
  }, [page, seq]);

  // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setShowScrollUp(true);
      } else {
        setShowScrollUp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // 다음 페이지를 불러오는 함수
  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // 맨 위로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // useEffect(() => {
  //   if ("scrollRestoration" in window.history) {
  //     window.history.scrollRestoration = "manual"; // 기본값은 'auto'
  //   }
  // }, []);

  const deletedFilter = (deletedSeq) => {
    setConyQuestionComment((prevComments) =>
      prevComments.filter((el) => el.seq !== deletedSeq)
    );
    toast.success("삭제되었습니다.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <div className="wrapper">
      <div className="relative flex justify-center over">
        <BackHeader
          isBackshow={false}
          title="코니질문"
          isAlarmShow={true}
          designatedUrl="/home"
        />
        <NavBottom />
        <ToastContainer />
        <div className="bg-white minWrapper pt-[60px] mb-[90px]">
          {getConyQuestion?.data?.data && (
            <QuestionBannerCard
              openDate={getConyQuestion?.data?.data?.openDate}
              title={getConyQuestion?.data?.data?.content}
              isNavigate={true}
              navigateUrl="/conyQuestion/all"
              cutting={false}
              isMenuIcon={true}
            />
          )}
          <div className="mt-[15px]">
            {showScrollUp && (
              <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
                <button type="button" onClick={scrollToTop}>
                  <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                </button>
              </div>
            )}
            {conyQuestionComment?.length > 0 ? (
              <InfiniteScroll
                dataLength={conyQuestionComment?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                endMessage={
                  <>
                    {conyQuestionComment?.length > 0 && (
                      <p className="text-center text-gray-gray">
                        모든 댓글을 불러왔습니다.
                      </p>
                    )}
                  </>
                }
              >
                {conyQuestionComment.map((el, idx) => (
                  <div
                    key={idx}
                    onClick={() =>
                      navigate(
                        `/conyQuestion/comment/${getConyQuestion?.data?.data?.seq}/${el?.seq}?commentIdx=${idx}`,
                        {
                          state: {
                            designatedActivQuestioneUrl: `/conyQuestion`,
                          },
                        }
                      )
                    }
                  >
                    <PostCardInConyQuestion
                      deletedFilter={deletedFilter}
                      questionSeq={getConyQuestion?.data?.data?.seq}
                      {...el}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            ) : conyQuestionComment ? (
              <div className="mt-[100px]">
                <EmptyCard title={"답변이 존재하지 않습니다."} />
              </div>
            ) : (
              <LoadingCard />
            )}
          </div>
        </div>
        <div className="fixed bottom-0 minWrapper">
          <button
            onClick={() =>
              navigate(
                `/conyQuestion/answer/${getConyQuestion?.data?.data?.seq}`
              )
            }
            className="bg-main-purple h-[50px] w-[96px] rounded-full text-[16px] font-bold text-white absolute right-[7px] bottom-[87px]"
          >
            답변쓰기
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConyQuestion;
