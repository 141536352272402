// images
import cogen_symbol_light from 'assets/images/brand/cogen_symbol_light.png';
// icons
import { HiMiniChatBubbleOvalLeft } from 'react-icons/hi2';
import { IoHeart } from 'react-icons/io5';
import { GoHeartFill } from 'react-icons/go';
// utils
import { dateMatcher, postCategoryMathcnerByEng } from 'utils/matcher';
const CommentCard = ({
    seq,
    type,
    postType,
    postTitle,
    title,
    message,
    content,
    url,
    isRead = false,
    createdAt,
    isMessage = true,
}) => {
    return (
        <div className="border border-gray-sGray px-[20px] py-[20px]  min-h-[116px] rounded-[15px] mx-[22px] mb-[12px] bg-white">
            <div className="mr-2 ">
                <div className="text-text-lGray text-[14px] ">{dateMatcher(createdAt)}</div>
                {type === 'COMMENT' ? (
                    <div
                        className={
                            isRead
                                ? `text-[18px] font-bold text-text-lGray mt-1`
                                : `text-[18px] font-bold text-text-dGray mt-1`
                        }
                    >
                        <div className="max-h-[50px] break-words leading-tight text-ellipsis break-keep line-clamp-2">
                            {message || content}
                        </div>
                        {!isMessage && (
                            <div className="text-text-lGray text-[14px] pt-[10px] break-words text-ellipsis break-keep line-clamp-1">
                                {postTitle}
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className={
                            isRead
                                ? `text-[18px] font-bold text-text-lGray mt-1 flex items-start`
                                : `text-[18px] font-bold text-text-dGray mt-1 flex items-start`
                        }
                    >
                        <IoHeart className="w-4 h-4 mr-1 mt-[4px]" />
                        <div className="max-h-[50px] break-words leading-tight text-ellipsis break-keep line-clamp-2 font-bold">
                            {message}
                        </div>
                    </div>
                )}
            </div>

            {isMessage && (
                <div className="flex justify-between mt-2">
                    <div className="text-text-lGray text-[14px] font-bold line-clamp-1">
                        {title ? title : postCategoryMathcnerByEng(postType)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommentCard;
