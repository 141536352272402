// hooks
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestGet, useRequestPost, useRequestPatch } from 'hooks/useRequest';
// common
import VerfyInput from 'components/inputs/VerifyInput';
import CloseHeader from 'components/headers/CloseHeader';
import TwoColRedTags from 'components/tags/TwoColRedTag';
import FourColBlackTag from 'components/tags/FourColBlackTag';
// icons
import { FaCamera } from 'react-icons/fa6';
import { IoIosArrowForward } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';

// common
import { toast, ToastContainer } from 'react-toastify'; // Toastify import

const MyCogenEditInfo = () => {
    const navigate = useNavigate();

    const profileTempImg = '';
    let getUserInfo = useRequestGet({
        requestUrl: '/api/users/profile',
        queryKey: 'userInfo',
    });
    const [nickname, setNickname] = useState('');
    const [verifyNickname, setVerifyNickname] = useState(false);
    const [gender, setGender] = useState(null);
    const [age, setAge] = useState(null);

    // init
    useEffect(() => {
        if (getUserInfo?.data?.data) {
            setNickname(getUserInfo?.data?.data?.nickname);
            setGender(getUserInfo?.data?.data?.gender);
            setAge(getUserInfo?.data?.data?.ageGroup);
        }
    }, [getUserInfo?.data]);

    // nickname
    useEffect(() => {
        // setVerifyNickname(false);
        if (nickname !== getUserInfo?.data?.data?.nickname) {
            setVerifyNickname({
                state: 'error',
                message: '닉네임 중복 확인이 필요합니다.',
            });
        } else if (nickname === getUserInfo?.data?.data?.nickname) setVerifyNickname(true);
    }, [nickname]);
    const postVerifyNickname = useRequestPost();
    const handleVerifyNickname = () => {
        postVerifyNickname.mutate({
            requestUrl: '/api/users/check-nickname',
            postData: {
                nickname: nickname,
            },
        });
    };
    const handleSubmitNickname = () => {
        const nicknameRegex = /^[a-zA-Z0-9_\-!@#$%^&*()\uAC00-\uD7A3]{2,9}$/;
        if (nicknameRegex.test(nickname) && getUserInfo?.data?.data?.nickname !== nickname) {
            handleVerifyNickname();
        } else if (nickname.length <= 0) {
            setVerifyNickname(false);
        } else {
            if (nickname.length < 2 || nickname.length > 9) {
                setVerifyNickname({
                    state: 'error',
                    message: '2글자 이상 10글자 미만의 닉네임을 입력하세요',
                });
            } else if (getUserInfo?.data?.data?.nickname === nickname) {
                setVerifyNickname({
                    state: 'success',
                    message: '현재 회원님께서 사용중인 닉네임 입니다',
                });
            } else {
                setVerifyNickname({
                    state: 'error',
                    message: '닉네임에 잘못된 문자가 포함되었습니다',
                });
            }
        }
    };
    useEffect(() => {
        if (postVerifyNickname.status === 'success' && postVerifyNickname?.data?.data?.isAvailable) {
            setVerifyNickname({
                state: 'success',
                message: '사용가능한 닉네임입니다.',
            });
        } else if (postVerifyNickname.status === 'success' && !postVerifyNickname.data.data.isAvailable) {
            setVerifyNickname({
                state: 'error',
                message: '중복된 닉네임이 있습니다.',
            });
        }
    }, [postVerifyNickname.status]);

    // profileImage
    //   const [selectedImage, setSelectedImage] = useState(null);
    const [fileData, setFileData] = useState([]);

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // 첫 번째 파일만 선택
        if (file) {
            const fileObject = {
                id: `${Date.now()}-${file.name}`,
                url: URL.createObjectURL(file),
                file: file, // 실제 파일 객체
            };
            setFileData(fileObject);
            //   setSelectedImage(fileObject.url); // 미리보기를 위한 이미지 URL 저장
        }
    };

    // patchUpdate
    const patchProfile = useRequestPatch();
    const handlePatchProfile = () => {
        if (fileData.file) {
            const TOKEN = localStorage.getItem('TOKEN');
            const formData = new FormData();
            // 'file'이라는 필드명으로 실제 파일을 FormData에 추가
            formData.append('image', fileData.file);
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/users/profile/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // 이 헤더를 반드시 추가
                        Authorization: `Bearer ${TOKEN}`, // 토큰 추가
                    },
                })
                .catch((error) => {
                    console.error('이미지 업로드 실패:', error);
                });
        }
        if (getUserInfo?.data?.data?.nickname === nickname || postVerifyNickname?.status === 'success') {
            patchProfile.mutate({
                requestUrl: '/api/users/profile/update',
                patchData: { nickname: nickname, ageGroup: age, gender: gender },
            });
        }
    };
    useEffect(() => {
        if (patchProfile?.status === 'success') {
            toast.success('회원 정보가 수정되었습니다.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }, [patchProfile?.status]);

    return (
        <div className="wrapper ">
            <div className="bg-white minWrapper pt-[60px] m-auto">
                <CloseHeader
                    title="회원 정보 수정"
                    postTitle="저장"
                    cloesNavigateURL={'/myCogen'}
                    activeFunc={handlePatchProfile}
                />
                <ToastContainer />

                <div className="pt-5">
                    <div className="w-[92px] h-[92px] rounded-full border border-[#cdcdcd] my-[22px] mx-auto relative">
                        {fileData?.url && getUserInfo?.data?.data?.profileImageUrl ? (
                            <div className="relative">
                                <div>
                                    <img
                                        src={fileData.url}
                                        alt="Selected preview"
                                        className="w-[92px] h-[92px] rounded-full object-cover"
                                    />
                                </div>
                                <div className="absolute right-[-4px] bottom-[-4px] w-[40px] h-[40px] bg-gray-sGray border border-gray-lGray rounded-full flex justify-center items-center cursor-pointer">
                                    <label htmlFor="fileInput">
                                        <FaCamera className="text-gray-lGray w-[19px] h-[19px]" />
                                    </label>
                                </div>
                                {/* 파일 선택 input (hidden) */}
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.heif,.heic"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </div>
                        ) : getUserInfo?.data?.data?.profileImageUrl && !fileData?.url ? (
                            <>
                                <img
                                    src={getUserInfo?.data?.data?.profileImageUrl}
                                    alt="profile"
                                    className="object-cover w-full h-full rounded-full"
                                />
                                <div className="absolute right-[-4px] bottom-[-4px] w-[40px] h-[40px] bg-gray-sGray border border-gray-lGray rounded-full flex justify-center items-center cursor-pointer">
                                    <label htmlFor="fileInput">
                                        <FaCamera className="text-gray-lGray w-[19px] h-[19px]" />
                                    </label>
                                </div>
                                {/* 파일 선택 input (hidden) */}
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.heif,.heic"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </>
                        ) : (
                            <div className="relative">
                                {/* 미리보기 영역 */}
                                {fileData?.url ? (
                                    <div>
                                        <img
                                            src={fileData.url}
                                            alt="Selected preview"
                                            className="w-[92px] h-[92px] rounded-full object-cover"
                                        />
                                    </div>
                                ) : (
                                    <FaUserCircle className="w-full h-full rounded-full text-gray-lGray" />
                                )}
                                {/* 카메라 아이콘 */}

                                <div className="absolute right-[-4px] bottom-[-4px] w-[40px] h-[40px] bg-gray-sGray border border-gray-lGray rounded-full flex justify-center items-center cursor-pointer">
                                    <label htmlFor="fileInput">
                                        <FaCamera className="text-gray-lGray w-[19px] h-[19px]" />
                                    </label>
                                </div>
                                {/* 파일 선택 input (hidden) */}
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.heif,.heic"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </div>
                        )}
                    </div>
                    <div className="px-[22px]">
                        <VerfyInput
                            value={nickname}
                            setValue={setNickname}
                            placeholder="닉네임"
                            type="grayType"
                            verifyBtnTitle="중복확인"
                            resetBtn={true}
                            state={verifyNickname?.state}
                            stateMessage={verifyNickname?.message}
                            onClickFunc={handleSubmitNickname}
                        />
                    </div>
                    <div
                        className="h-[50px] text-[18px] font-semibold ml-[22px] mt-[22px] flex justify-between"
                        onClick={() => navigate('/myCogen/editPW')}
                    >
                        <div>비밀번호 재설정</div>
                        <div className="w-[50px] h-[50px]">
                            <IoIosArrowForward className="w-[30px] h-[30px] text-gray-lGray" />
                        </div>
                    </div>

                    <div className="mx-[22px]">
                        <div className="mb-3 font-bold text-[16px]">성별</div>
                        <TwoColRedTags
                            selectedTag={gender}
                            setSelectedTag={setGender}
                            tags={[
                                { gender: 'MALE', genderKR: '남성' },
                                { gender: 'FEMALE', genderKR: '여성' },
                            ]}
                            mainKey="gender"
                            viewKey="genderKR"
                        />
                    </div>

                    <div className="mx-[22px] mt-4">
                        <div className="mb-3 font-bold text-[16px]">연령대</div>
                        <FourColBlackTag
                            selectedTag={age}
                            setSelectedTag={setAge}
                            tags={[
                                { ageGroup: 'TEENS', ageKR: '10대' },
                                { ageGroup: 'TWENTIES', ageKR: '20대' },
                                { ageGroup: 'THIRTIES', ageKR: '30대' },
                                { ageGroup: 'FORTIES', ageKR: '40대' },
                                { ageGroup: 'FIFTIES', ageKR: '50대' },
                                { ageGroup: 'SIXTIES', ageKR: '60대' },
                                { ageGroup: 'SEVENTIES', ageKR: '70대' },
                                { ageGroup: 'EIGHTIES_AND_ABOVE', ageKR: '80대' },
                            ]}
                            mainKey="ageGroup"
                            viewKey="ageKR"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCogenEditInfo;
