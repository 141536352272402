import { configureStore } from "@reduxjs/toolkit";
import alarmReducer from "./alarmSlice";

const store = configureStore({
  reducer: {
    alarm: alarmReducer,
  },
});

export default store;
