// font
import './styles/fonts/SUIT-Variable.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // Toastify 스타일 import

// pages
import Intro from 'pages/intro';
import SignIn from 'pages/signIn';
import SignUp from 'pages/signUp';
import Policy from 'pages/policy';
import Alarm from 'pages/alarm';

import Home from 'pages/home';
import ConyQuestion from 'pages/conyQuestion';
import ConyQuestionAll from 'pages/conyQuestionAll';
import ConyQuestionAllId from 'pages/conyQuestionAllId';
import ConyQuestionComment from 'pages/conyQuestionComment';
import ConyQuestionAnswer from 'pages/conyQuestionAnswer';
import ConyQuestionAnswerEdit from 'pages/conyQuestionAnswerEdit';

import CogenLife from 'pages/cogenLife';
import CogenLifeCategory from 'pages/cogenLifeCategory';
import CogenLifeCategoryId from 'pages/cogenLifeCategoryId';
import CogenLifeSearch from 'pages/cogenLifeSearch';
import CogenLifePost from 'pages/cogenLifePost';
import CogenLifeEdit from 'pages/cogenLifeEdit';

import MyCogen from 'pages/myCogen';
import MyCogenEditInfo from 'pages/myCogenEditInfo';
import MyCogenEditPW from 'pages/myCogenEditPW';
import MyCogenStorage from 'pages/myCogenStorage';
import MyCogenComment from 'pages/myCogenComment';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/signIn"
                    element={<SignIn />}
                />
                <Route
                    path="/signUp"
                    element={<SignUp />}
                />
                <Route
                    path="/policy"
                    element={<Policy />}
                />

                <Route
                    path="/alarm"
                    element={<Alarm />}
                />

                {/* navs */}
                <Route
                    path="/home"
                    element={<Home />}
                />
                {/* conyQuestion */}
                <Route
                    path="/conyQuestion/comment/:questionSeq/:commentSeq"
                    element={<ConyQuestionComment />}
                />
                <Route
                    path="/conyQuestion"
                    element={<ConyQuestion />}
                />
                <Route
                    path="/conyQuestion/all"
                    element={<ConyQuestionAll />}
                />
                <Route
                    path="/conyQuestion/all/:id"
                    element={<ConyQuestionAllId />}
                />

                <Route
                    path="/conyQuestion/answer/:id"
                    element={<ConyQuestionAnswer />}
                />
                <Route
                    path="/conyQuestion/answerEdit/:questionSeq/:commentSeq"
                    element={<ConyQuestionAnswerEdit />}
                />

                {/* conyLife */}
                <Route
                    path="/cogenLife"
                    element={<CogenLife />}
                />
                <Route
                    path="/cogenLife/:category"
                    element={<CogenLifeCategory />}
                />
                <Route
                    path="/cogenLife/:category/:id"
                    element={<CogenLifeCategoryId />}
                />
                <Route
                    path="/cogenLife/search"
                    element={<CogenLifeSearch />}
                />
                <Route
                    path="/cogenLife/post"
                    element={<CogenLifePost />}
                />
                <Route
                    path="/cogenLife/edit/:category/:id"
                    element={<CogenLifeEdit />}
                />
                {/* myCogen */}
                <Route
                    path="/myCogen"
                    element={<MyCogen />}
                />
                <Route
                    path="/myCogen/editInfo"
                    element={<MyCogenEditInfo />}
                />
                <Route
                    path="/myCogen/editPW"
                    element={<MyCogenEditPW />}
                />
                <Route
                    path="/myCogen/storage"
                    element={<MyCogenStorage />}
                />
                <Route
                    path="/myCogen/comment"
                    element={<MyCogenComment />}
                />

                {/* Intro */}
                <Route
                    path="/"
                    element={<Intro />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
