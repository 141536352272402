// icons
import { IoClose } from "react-icons/io5";

const SearchInput = ({
  value,
  setValue,
  placeholder = "",
  type = "grayType",
  resetBtn = false,
  activeBtnFunc,
  activeBtnTitle = "검색",
}) => {
  const styleType = {
    grayType:
      "font-semibold bg-gray-white  rounded-full flex items-center justify-between",
    whiteType: "",
    whiteBorderType:
      "font-semibold border rounded-full text-white flex items-center justify-between",
    transparentWhiteType:
      "font-semibold bg-[#ffffff30] text-white rounded-full flex items-center justify-between",
  };
  return (
    <div className={`${styleType[type]}`}>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className="w-full p-4 text-[16px] bg-transparent border-none outline-none placeholder-text-gray focus:outline-none"
      />
      {resetBtn && (
        <IoClose
          onClick={() => setValue("")}
          className="w-10 h-10 p-2 mr-2 text-white"
        />
      )}
      <button
        type="button"
        onClick={() => activeBtnFunc && activeBtnFunc()}
        className="w-[80px] h-[30px] bg-back-dGray text-white rounded-full mr-4"
      >
        {activeBtnTitle}
      </button>
    </div>
  );
};

export default SearchInput;
