import { useEffect, useState } from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";
// icons
import { IoIosArrowForward } from "react-icons/io";
const StaticMap = ({ address }) => {
  const [position, setPosition] = useState({
    lat: 37.566826,
    lng: 126.9786567,
  }); // 초기 좌표: 서울 중심
  const [isLoaded, setIsLoaded] = useState(false); // 로딩 여부

  useEffect(() => {
    const { kakao } = window;

    if (!kakao) {
      // console.log('Kakao Maps SDK is not loaded');
      return;
    }

    if (!address) return;
    const geocoder = new kakao.maps.services.Geocoder();
    // 주소를 좌표로 변환하는 함수
    geocoder.addressSearch(
      address.road_address_name,
      function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          const lat = result[0].y;
          const lng = result[0].x;

          // 좌표 업데이트
          setPosition({ lat, lng });
          setIsLoaded(true); // 좌표 변환 완료 시 로딩 상태 업데이트
        } else {
          return;
          // alert("해당 주소를 찾을 수 없습니다.");
        }
      }
    );
  }, [address?.road_address_name]);
  return (
    <>
      {address && (
        <div className="w-full h-[400ppx] relative">
          <Map
            center={{ lat: position.lat - 0.0004, lng: position.lng }} // 변환된 좌표로 지도 중앙을 설정
            style={{ width: "100%", height: "150px", borderRadius: 10 }} // 지도 크기
            level={4} // 확대 수준
            draggable={false} // 드래그 비활성화
          >
            <MapMarker position={position} />
          </Map>

          <div className="absolute z-[1] flex justify-center w-full bottom-[6px] bg-none">
            <div
              onClick={() =>
                window.open(
                  `https://map.kakao.com/?q=${encodeURIComponent(
                    address.road_address_name
                  )}`,
                  "_blank"
                )
              }
              className="z-30  w-[98%] bg-white h-[64px] rounded-[10px] flex justify-between items-center"
            >
              <div className="ml-[22px]">
                <div className="text-text-dGray font-semibold text-[16px]">
                  {address.place_name}
                </div>
                <div className="text-text-lGray text-[14px]">
                  {address.road_address_name}
                </div>
              </div>
              <div className="w-[22px] flex justify-center items-center mr-[10px] h-[22px]">
                <IoIosArrowForward className="text-gray-lGray" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StaticMap;
