// hooks
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'api/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
// commons
import NavBottom from 'components/Nav/NavBottom';
import FilterTag from 'pages/cogenLife/components/FilterTag';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
import CogenLifeSearchHeader from 'components/headers/CongenLifeSearchHeader';
const CogenLifeSearch = () => {
    // const [postList, setPostList] = useState(null);
    // const [postType, setPostType] = useState("DAILY_CHAT");
    const [searchType, setSearchType] = useState('title');
    const [searchInput, setSearchInput] = useState('');
    const [searchTrigger, setSearchTrigger] = useState(0); // 검색 트리거 상태 추가

    const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
    // const [page, setPage] = useState(1);

    const navigate = useNavigate();
    // const getPostList = () => {
    //   if (postType && searchType && searchInput) {
    //     axios
    //       .get(
    //         `/api/posts/list/{postType}?searchType=${searchType}&search=${searchInput}&page=1&limit=10`
    //       )
    //       .then((res) => setPostList(res.data?.data))
    //       .catch((err) => console.log(err));
    //   }
    // };

    const [postList, setPostList] = useState([]);
    const [postListPage, setPostListPage] = useState(1);
    const [postListHasMore, setPostListHasMore] = useState(true);

    const getPostList = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 100));

            const { data } = await axios.get(
                `/api/posts/list/{postType}?searchType=${searchType}&search=${searchInput}&page=${currentPage}&limit=10`
            );
            const newPostList = data.data;

            // 이전 데이터에 새 데이터를 추가
            setPostList((prevPostList) => {
                const uniquePostList = [...prevPostList, ...newPostList].reduce((acc, newPost) => {
                    if (!acc.find((post) => post.seq === newPost.seq)) {
                        acc.push(newPost);
                    }
                    return acc;
                }, []);
                return uniquePostList;
            });
            setPostListHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    // 검색시 데이터를 불러옴
    useEffect(() => {
        if (searchTrigger) {
            getPostList(postListPage);
        }
    }, [postListPage, searchTrigger]);
    useEffect(() => {
        if (searchTrigger && searchInput) {
            setPostListPage(1); // 페이지 초기화
            setPostList([]); // 기존 게시물 초기화
            getPostList(1);
        }
    }, [searchType]);
    // 첫 검색을 누른 경우
    const handleInitSearch = () => {
        if (searchInput) {
            // 초기 상태 설정
            setPostList([]); // 기존 게시물 초기화
            setPostListPage(1); // 페이지 초기화
            setSearchTrigger((prev) => prev + 1); // searchTrigger 증가 -> 검색 요청 트리거
        }
    };
    // 다음 페이지를 불러오는 함수
    const fetchMorePostList = () => {
        setPostListPage((prevPage) => prevPage + 1);
    };

    // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // 맨 위로 스크롤하는 함수

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className="flex justify-center wrapper ">
            <NavBottom />

            <div className="h-full bg-white minWrapper mb-[70px]">
                <CogenLifeSearchHeader
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    activeFunc={handleInitSearch}
                />

                <div className="bg-white minWrapper pt-[50px]">
                    <div className="px-4 mt-[15px]">
                        <FilterTag
                            selectedTag={searchType}
                            setSelectedTag={setSearchType}
                            tags={[
                                { setSearchType: 'title', setSearchTypeKR: '제목별' },
                                { setSearchType: 'content', setSearchTypeKR: '본문별' },
                                {
                                    setSearchType: 'title_content',
                                    setSearchTypeKR: '제목+본문',
                                },
                            ]}
                            mainKey="setSearchType"
                            viewKey="setSearchTypeKR"
                        />
                    </div>
                    <div className="mt-[15px]">
                        {!postList ? (
                            <></>
                        ) : postList.length > 0 ? (
                            <InfiniteScroll
                                dataLength={postList.length}
                                next={fetchMorePostList}
                                hasMore={postListHasMore}
                                endMessage={
                                    <>
                                        {postList?.length > 0 && (
                                            <p className="text-center text-gray-gray pb-4">
                                                모든 검색결과를 불러왔습니다.
                                            </p>
                                        )}
                                    </>
                                }
                            >
                                {postList.map((el, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => navigate(`/cogenLife/${el.postType}/${el.seq}`)}
                                    >
                                        <PostCard {...el} />
                                    </div>
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <div className="mt-[200px]">
                                <EmptyCard title={'검색 결과 존재하지 않습니다.'} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CogenLifeSearch;
