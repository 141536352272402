// hooks
import { useEffect, useState } from "react";
import { useRequestGet, useRequestPatch } from "hooks/useRequest";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// icons
import { FaCheck } from "react-icons/fa6";
// commons
import CloseHeader from "components/headers/CloseHeader";
import ConyAnswerCard from "components/cards/ConyAnswerCard";
import DefaultTextArea from "components/textarea/DefaultTextArea";
import { toast, ToastContainer } from "react-toastify"; // Toastify import

const ConyQuestionEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const [content, setContent] = useState(state.content);
  const paramQuestionSeq = useParams()?.questionSeq;
  const paramCommentSeq = useParams()?.commentSeq;

  const getConyQuestion = useRequestGet({
    requestUrl: `/api/questions/${paramQuestionSeq}`,
    queryKey: `getConyQuestion-${paramQuestionSeq}`,
  });

  // init
  const patchAnswer = useRequestPatch();
  const handlePatchAnswer = () => {
    if (content && content.length >= 2)
      patchAnswer.mutate({
        requestUrl: `api/questions/${paramQuestionSeq}/comments/${paramCommentSeq}`,
        patchData: { content: content },
      });
    else {
      if (content.length < 2) {
        toast.error("최소 두글자 이상 입력하세요.", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else
        toast.error("필수 항목을 입력하세요.", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }
  };
  useEffect(() => {
    if (patchAnswer.status === "success") {
      navigate(-1);
    }
  }, [patchAnswer.status]);
  return (
    <div className="wrapper h-auto max-h-[100vh] flex justify-center">
      <div className="h-full bg-white minWrapper">
        <ToastContainer />
        <CloseHeader
          title="답변쓰기"
          // cloesNavigateURL={"/conyQuestion"}
          activeFunc={handlePatchAnswer}
        />
        <div className="bg-white pt-[60px] mx-[22px] mb-[22px]">
          <ConyAnswerCard {...getConyQuestion?.data?.data} />
          <div className="flex items-center mt-4 mb-2 font-bold text-main-red">
            <FaCheck className="mr-1" />
            <div>나의 답변은?</div>
          </div>
          <div className="mt-4">
            <DefaultTextArea content={content} setContent={setContent} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConyQuestionEdit;
