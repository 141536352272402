// hooks
import { useRequestGet, useRequestPatch } from 'hooks/useRequest';
import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'api/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
// commons
import MainHeader from 'components/headers/MainHeader';
import NavBottom from 'components/Nav/NavBottom';
import CommentCard from 'components/cards/CommentCard';
// import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import BackHeader from 'components/headers/BackHeader';
// icons
import { TbAlarmMinusFilled } from 'react-icons/tb';
import { FaCheck } from 'react-icons/fa6';
import { IoIosArrowUp } from 'react-icons/io';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setIsAlarm } from '../../redux/alarmSlice';
const Alarm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const alarm = useSelector((state) => state.alarm.isAlarm);

    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState('COMMENT');
    // 모든 알림 읽음 여부를 저장하는 상태
    const alarm = useSelector((state) => state.alarm.isAlarm);

    const getAlarms = useRequestGet({
        requestUrl: `/api/notifications?page=1&limit=100`,
        queryKey: 'allAlarm',
    });
    useEffect(() => {
        if (getAlarms?.data?.data?.data && getAlarms.status === 'success') {
            const allRead = getAlarms?.data?.data?.data?.every((alarm) => alarm.isRead);
            dispatch(setIsAlarm(allRead));
        }
    }, [getAlarms.status, getAlarms?.data?.data?.data]);

    const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // 맨 위로 스크롤하는 함수
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // 모든 알림 읽기
    const readAllNotification = useRequestPatch();
    const handleReadAllNotification = () => {
        readAllNotification.mutate({
            requestUrl: `/api/notifications/read-all`,
        });
    };
    useEffect(() => {
        if (readAllNotification.status === 'success') {
            dispatch(setIsAlarm(true));
            getAlarms.refetch();
        }
    }, [readAllNotification.status]);

    // 하나의 알림 읽기
    const readNotification = useRequestPatch();
    const handleReadNotification = (el) => {
        // 아직 읽지 않은(isRead: false) 알림을 찾음
        const postSeq = el.url.split('/')[2];
        const unreadAlarms = getAlarms?.data?.data?.data.filter((alarm) => !alarm.isRead);
        if (unreadAlarms.length === 1 && unreadAlarms[0].seq === el.seq) {
            // 모두 읽음 상태로 설정
            dispatch(setIsAlarm(true));
            // 알림 읽기 요청
        }
        readNotification.mutate({
            requestUrl: `/api/notifications/${el.seq}/read`,
        });

        navigate(`/cogenLife/${el.postType}/${postSeq}`);
    };

    return (
        <div className="flex justify-center wrapper">
            <div className="minWrapper">
                <BackHeader
                    title="알림"
                    isAlarmShow={true}
                />
                {showScrollUp && (
                    <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
                        <button
                            type="button"
                            onClick={scrollToTop}
                        >
                            <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                        </button>
                    </div>
                )}

                {/*
        디자이너님과 조율 필요
        <div className="minWrapper z-10 top-[50px] fixed bg-white">
          <div className="flex justify-between font-bold text-gray-lGray h-[40px]">
            <div
              className={`w-full border-b-2 text-center pb-1 ${
                tab === "POST" && "text-main-purple border-main-purple"
              } `}
              onClick={() => setTab("POST")}
            >
              게시글
            </div>
            <div
              className={`w-full border-b-2 text-center pb-1 ${
                tab === "COMMENT" && "text-main-purple border-main-purple"
              } `}
              onClick={() => setTab("COMMENT")}
            >
              댓글
            </div>
          </div>
        </div> */}
                <div className="flex justify-center min-h-screen">
                    <NavBottom />

                    <div className="bg-white minWrapper mt-[50px]">
                        {tab === 'COMMENT' ? (
                            <div className="bg-[#F7F6FF] pt-[10px] h-full pb-[80px]">
                                <button
                                    onClick={() => !alarm && handleReadAllNotification()}
                                    className=" text-center pb-[10px] m-auto flex justify-center items-center  font-bold"
                                >
                                    <div className="flex justify-center items-center w-[20px] h-[20px]">
                                        <FaCheck
                                            className={alarm ? 'text-gray-lGray mr-2 ' : 'text-main-purple mr-2 '}
                                        />
                                    </div>
                                    <div
                                        className={
                                            alarm ? 'text-text-lGray text-[14px]' : 'text-main-purple text-[14px]'
                                        }
                                    >
                                        모두 읽음
                                    </div>
                                </button>

                                {getAlarms?.data?.data?.data?.map((el, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => handleReadNotification(el)}
                                    >
                                        <CommentCard {...el} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="bg-[#F7F6FF] pt-[15px] h-full pb-[80px]">
                                <CommentCard type="comment" />
                                <CommentCard type="heart" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alarm;
