// images
import cogen_symbol_light from "assets/images/brand/cogen_symbol_light.png";
// icons
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { IoHeart } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
// utils
import {
  ageGroupStyleMatcher,
  postCategoryMathcnerByEng,
  dateMatcher,
} from "utils/matcher";
const PostCard = ({
  postType,
  authorAgeGroup,
  authorNickname,
  authorProfileImageUrl,
  title,
  content,
  createdAt,
  modifiedAt,
  likeCount,
  viewCount,
  commentCount,
  thumbnailUrl,
  seq,
  type,
}) => {
  return (
    <div className="border border-gray-sGray p-[20px] rounded-[15px] mx-[22px] mb-[15px] bg-white ">
      <div className="flex justify-between ">
        <div className="flex-grow">
          {/*  <div className="flex-grow mr-2"> 또는
                    <div className="w-full max-w-[calc(100%-80px)] mr-2"> */}
          {/* 위 방법 기억해두기 max-w-[calc(100%-80px)]*/}
          <div className="flex items-center justify-between">
            <div className="flex items-center ">
              {authorProfileImageUrl ? (
                <div className="rounded-full w-[36px] h-[36px] flex-shrink-0 border border-[#CDCDCD]">
                  <img
                    src={authorProfileImageUrl}
                    alt={"authorProfileImageUrl"}
                    className="w-full h-full rounded-full "
                  />
                </div>
              ) : (
                <div className="rounded-full  w-[36px] h-[36px] ">
                  <FaUserCircle className="w-full h-full text-gray-lGray" />
                </div>
              )}
              <div>
                <div>
                  {ageGroupStyleMatcher(authorAgeGroup, authorNickname + "")}
                </div>
                <div className="text-text-lGray text-[14px] ml-2 font-bold mt-[-2px]">
                  {dateMatcher(createdAt)}
                </div>
              </div>
            </div>
          </div>
          <div className="text-[18px] font-bold text-text-dGray mt-1 line-clamp-1 break-all">
            {title ? title : content}
          </div>
        </div>
        {thumbnailUrl && (
          <div className="w-[70px] h-[70px] border border-[#D9D9D9] rounded-[5px] ml-3 flex-shrink-0">
            <img
              src={thumbnailUrl}
              alt={title}
              className="w-full h-full rounded-[4px] object-cover"
            />
          </div>
        )}
      </div>

      <div className="flex justify-between mt-2">
        {postType && (
          <div className="text-text-lGray text-[14px] font-bold">
            {postCategoryMathcnerByEng(postType)}
          </div>
        )}
        <div className="flex items-center font-semibold">
          <HiMiniChatBubbleOvalLeft className="mr-1 text-gray-lGray" />
          <div className="mr-2 text-text-gray">
            {commentCount ? commentCount : 0}{" "}
          </div>
          <IoHeart className="mr-1 text-gray-lGray" />
          <div className="text-text-gray">{likeCount ? likeCount : 0} </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
