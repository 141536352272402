// hooks
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRequestPatch, useRequestDelete } from "hooks/useRequest";
// icons
import { IoHeart } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { IoArrowUndoSharp } from "react-icons/io5";
import editIcon from "assets/images/common/editIcon.png";
import deleteIcon from "assets/images/common/deleteIcon.png";
// utils
import { dateMatcher, ageGroupStyleMatcher } from "utils/matcher";
// common
import CommentInput from "components/inputs/CommentInput";
import DeleteComfirmModal from "components/modals/DeleteComfirmModal";

const ReplyCard = ({
  seq,
  content,
  authorNickname,
  authorProfileImageUrl,
  authorAgeGroup,
  isLiked = false,
  likeCount = 0,
  replies = [],
  createdAt = "",
  modifiedAt = "",
  parentCommentSeq,
  parentCommentAuthorNickname = "",
  deletedFilter,
  userNickname,
  commentList, // 부모에서 받은 commentList
  setCommentList, // 부모에서 받은 setter
}) => {
  const paramId = useParams().id;
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteComment = useRequestDelete();
  const handleDeleteComment = (commentSeq) => {
    deleteComment.mutate({
      requestUrl: `/api/posts/${paramId}/comments/${commentSeq}`,
      deleteData: {},
    });
  };
  useEffect(() => {
    if (deleteComment.status === "success") {
      deletedFilter(seq);
    }
  }, [deleteComment.status]);

  const patchLike = useRequestPatch();
  const [isCurLiked, setIsCurLiked] = useState(isLiked);
  const [curLikeCount, setCurLikeCount] = useState(likeCount);

  const handlePatchLike = (commentSeq) => {
    // 대댓글 좋아요 상태를 토글
    const newLikedState = !isCurLiked;
    setIsCurLiked(newLikedState);
    // 서버에 좋아요 토글 요청
    patchLike.mutate({
      requestUrl: `/api/comments/likes/toggle/${commentSeq}`,
    });
    // 좋아요 카운트를 증가 또는 감소
    const newLikeCount = newLikedState ? curLikeCount + 1 : curLikeCount - 1;
    setCurLikeCount(newLikeCount);
    // commentList 업데이트 (부모 댓글과 해당 대댓글의 상태를 변경)
    setCommentList((prevComments) =>
      prevComments.map((comment) =>
        comment.seq === parentCommentSeq // 부모 댓글을 찾음
          ? {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.seq === commentSeq // 대댓글을 찾아서 좋아요 상태와 카운트 업데이트
                  ? {
                      ...reply,
                      isLiked: newLikedState, // 좋아요 상태 업데이트
                      likeCount: newLikeCount, // 좋아요 카운트 업데이트
                    }
                  : reply
              ),
            }
          : comment
      )
    );
  };

  const [curReply, setCurReply] = useState(content);
  const [activeEdit, setActiveEdit] = useState(false);
  const [editValue, setEditValue] = useState(content);
  const patchComment = useRequestPatch();
  const handlePatchComment = (commentSeq, content) => {
    if (content) {
      patchComment.mutate({
        requestUrl: `/api/posts/${paramId}/comments/${commentSeq}`,
        patchData: {
          content: content,
        },
      });
    }
  };
  useEffect(() => {
    if (patchComment?.status === "success" && editValue) {
      // setCurReply(editValue);
      setActiveEdit(false);
      setCommentList((prevComments) =>
        prevComments.map((comment) =>
          comment.seq === parentCommentSeq // 부모 댓글을 찾음
            ? {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.seq === seq // 대댓글을 찾아서 content 업데이트
                    ? {
                        ...reply,
                        content: editValue, // 수정된 content 적용
                      }
                    : reply
                ),
              }
            : comment
        )
      );
    }
  }, [patchComment?.status]);
  useEffect(() => {
    if (activeEdit) setEditValue(content);
  }, [activeEdit]);

  useEffect(() => {
    // parentCommentSeq에 해당하는 부모 댓글을 찾음
    const currentComment = commentList?.find(
      (comment) => comment.seq === parentCommentSeq
    );
    // 부모 댓글의 replies 배열에서 현재 댓글 seq에 해당하는 대댓글을 찾음
    const currentReply = currentComment?.replies?.find(
      (reply) => reply.seq === seq
    );
    // 대댓글이 존재하면 curReply 상태 업데이트
    if (currentReply) {
      setCurReply(currentReply.content);
      setIsCurLiked(currentReply.isLiked);
      setCurLikeCount(currentReply.likeCount);
    }
  }, [commentList, parentCommentSeq, seq]);

  return (
    <div className={`border-gray-sGray px-[22px] py-[7.5px]`}>
      <div className="flex">
        <div className="w-[25px] h-[25px] mt-[10px]">
          <IoArrowUndoSharp className="rotate-180 text-gray-lGray " />
        </div>
        <div className="border border-gray-sGray rounded-[15px] p-[16px] w-full">
          <div className="flex justify-between">
            <div className="flex items-center">
              {authorProfileImageUrl ? (
                <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
                  <img
                    src={authorProfileImageUrl}
                    alt={"authorProfileImageUrl"}
                    className="w-full h-full rounded-full"
                  />
                </div>
              ) : (
                <div className="rounded-full  w-[36px] h-[36px]">
                  <FaUserCircle className="w-full h-full text-gray-lGray" />
                </div>
              )}
              <div className="font-bold">
                <div className="text-[14px]  text-text-gray ">
                  {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
                </div>
                <div className="ml-[8px] text-text-lGray text-[14px] mt-[-2px]">
                  {dateMatcher(createdAt)}
                </div>
              </div>
            </div>
            <div>
              {userNickname && userNickname === authorNickname && (
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => setActiveEdit(seq)}
                    className="flex items-center"
                  >
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-[20px] h-[20px]"
                    />
                    <div className="text-[14px] bold text-text-gray">수정</div>
                  </button>
                  <DeleteComfirmModal
                    isShow={isDeleteModal}
                    setIsShow={setIsDeleteModal}
                    title={"삭제하시겠습니까?"}
                    modalBtn={
                      <button
                        type="button"
                        onClick={() => setIsDeleteModal(true)}
                        className="flex items-center ml-[2px]"
                      >
                        <img
                          src={deleteIcon}
                          alt="deleteIcon"
                          className="w-[20px] h-[20px]"
                        />
                        <div className="text-[14px] bold text-main-red">
                          삭제
                        </div>
                      </button>
                    }
                    activeFunc={() => handleDeleteComment(seq)}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {seq === activeEdit ? (
              <div className="text-[15px] h-[50px] relative font-bold text-text-dGray mt-2">
                <div className="absolute z-20 w-full">
                  <CommentInput
                    value={editValue}
                    setValue={setEditValue}
                    btnTitle={"수정"}
                    activeFunc={() => handlePatchComment(seq, editValue)}
                  />
                </div>
                <div
                  onClick={() => setActiveEdit(false)}
                  className="fixed inset-0 z-10 w-screen h-screen"
                ></div>
              </div>
            ) : (
              <div className="text-[15px] font-bold text-text-dGray mt-1 break-all">
                {curReply}
              </div>
            )}
          </div>
          {/* 좋아요 */}
          <button
            type="button"
            onClick={() => handlePatchLike(seq)}
            className="flex items-center"
          >
            <IoHeart
              className={
                isCurLiked
                  ? "text-main-red w-[14px] mr-[2px] h-[14px]"
                  : "text-gray-lGray w-[14px] mr-[2px] h-[14px]"
              }
            />
            <span
              className={
                isCurLiked
                  ? "text-[14px] font-bold text-main-red"
                  : "text-[14px] font-bold text-text-gray"
              }
            >
              {curLikeCount}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReplyCard;
