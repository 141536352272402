import juniorIcon from 'assets/images/profiles/juniorIcon.png';
import seniorIcon from 'assets/images/profiles/seniorIcon.png';

// gender
export const genderMatcherByEng = (gender) => {
    switch (gender) {
        case 'MALE':
            return '남성';
        case 'FEMALE':
            return '여성';
        default:
            return gender;
    }
};
export const genderMatcherByKor = (gender) => {
    switch (gender) {
        case 'MALE':
            return '남성';
        case 'FEMALE':
            return '여성';
        default:
            return gender;
    }
};

// ageGroup

export const ageGroupMatcherByEng = (ageGroup) => {
    switch (ageGroup) {
        case 'TEENS':
            return '10대';
        case 'TWENTIES':
            return '20대';
        case 'THIRTIES':
            return '30대';
        case 'FORTIES':
            return '40대';
        case 'FIFTIES':
            return '50대';
        case 'SIXTIES':
            return '60대';
        case 'SEVENTIES':
            return '70대';
        case 'EIGHTIES_AND_ABOVE':
            return '80대';
        default:
            return ageGroup;
    }
};

export const ageGroupMatcherByKor = (ageGroup) => {
    switch (ageGroup) {
        case '10대':
            return 'TEENS';
        case '20대':
            return 'TWENTIES';
        case '30대':
            return 'THIRTIES';
        case '40대':
            return 'FORTIES';
        case '50대':
            return 'FIFTIES';
        case '60대':
            return 'SIXTIES';
        case '70대':
            return 'SEVENTIES';
        case '80대':
            return 'EIGHTIES_AND_ABOVE';
        default:
            return ageGroup;
    }
};

export const postCategoryMathcnerByEng = (category) => {
    switch (category) {
        case 'DAILY_CHAT':
            return '일상잡담';
        case 'WORRY_STORY':
            return '고민사연';
        case 'LITTLE_BOAST':
            return '깨알자랑';
        case 'ETC':
            return '기타등등';
        case 'FOOD_TRAVEL':
            return '맛집탐방';
        case 'CAFE_SNACK':
            return '카페간식';
        case 'HOBBY':
            return '취미생활';
        case 'TRAVEL_TOUR':
            return '여행투어';
        case 'BEAUTY':
            return '미용뷰티';
        case 'FITNESS_HEALTH':
            return '운동건강';
        case 'PET':
            return '반려동물';
        case 'EDUCATION_STUDY':
            return '교육공부';
        default:
            return category;
    }
};

export const postCategoryMathcnerByKor = (category) => {
    switch (category) {
        case '일상잡담':
            return 'DAILY_CHAT';
        case '고민사연':
            return 'WORRY_STORY';
        case '깨알자랑':
            return 'LITTLE_BOAST';
        case '기타등등':
            return 'ETC';
        case '맛집탐방':
            return 'FOOD_TRAVEL';
        case '카페간식':
            return 'CAFE_SNACK';
        case '취미생활':
            return 'HOBBY';
        case '여행투어':
            return 'TRAVEL_TOUR';
        case '미용뷰티':
            return 'BEAUTY';
        case '운동건강':
            return 'FITNESS_HEALTH';
        case '반려동물':
            return 'PET';
        case '교육공부':
            return 'EDUCATION_STUDY';
        default:
            return category;
    }
};

export const ageGroupStyleMatcher = (ageGroup, authorNickname) => {
    if (!ageGroup) return;
    if (!authorNickname) return;
    const numericAgeGroup = ageGroupMatcherByEng(ageGroup);
    const age = Number(numericAgeGroup?.slice(0, 2));
    if (age > 50) {
        return (
            <div className="text-[14px] font-bold text-main-purple ml-2 flex items-center">
                <div>{authorNickname}</div>
                <img
                    src={seniorIcon}
                    alt="seniorIcon"
                    className="w-[21px] h-[21px] ml-[5px]"
                />
            </div>
        );
    } else if (age <= 50) {
        return (
            <div className="text-[14px] font-bold text-main-skyBlue ml-2 flex items-center">
                <div>{authorNickname}</div>
                <img
                    src={juniorIcon}
                    alt="juniorIcon"
                    className="w-[21px] h-[21px] ml-[5px]"
                />
            </div>
        );
    } else {
        return (
            <div className="text-[14px] font-bold text-main-skyBlue ml-2 flex items-center">
                <div>{authorNickname}</div>
                <img
                    src={juniorIcon}
                    alt="juniorIcon"
                    className="w-[21px] h-[21px] ml-[5px]"
                />
            </div>
        );
    }
};

export const dateMatcher = (date) => {
    if (!date) return;
    const currentTime = new Date();
    const targetTime = new Date(date);

    const timeDifference = currentTime - targetTime;
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursDifference < 24) {
        if (minutesDifference < 60) {
            if (minutesDifference <= 0) return '방금전';
            return `${minutesDifference}분 전`;
        } else {
            return `${hoursDifference}시간 전`;
        }
    } else {
        // If more than 24 hours have passed, return the formatted date
        const tempDate = date.slice(0, 10);
        const processedDate = tempDate.replaceAll('-', '.');
        return processedDate;
    }
};
