// hooks
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// icons
import { TbBellFilled } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
// common
import AlarmIcon from "components/headers/AlarmIcon";

const BackHeader = ({
  isBackShow = true,
  title = "",
  isAlarmShow = false,
  designatedUrl = null,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const curLocation = location.pathname.split("/")[1];

  return (
    <div className="flex items-center justify-between z-10 h-[50px] bg-white fixed top-0 minWrapper m-auto">
      {isBackShow && (
        <div className="w-[50px] h-[50px] flex items-center justify-center">
          <IoIosArrowBack
            onClick={() =>
              designatedUrl ? navigate(designatedUrl) : navigate(-1)
            }
            className="w-7 h-7"
          />
        </div>
      )}

      {title && <div className="text-lg font-bold">{title}</div>}
      {!isAlarmShow && <div className="w-6 h-6"></div>}
      <div className="w-[50px] h-[50px]">{isAlarmShow && <AlarmIcon />}</div>
    </div>
  );
};

export default BackHeader;
