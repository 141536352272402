// hooks
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRequestGet } from "hooks/useRequest";
import axios from "api/axios";
import InfiniteScroll from "react-infinite-scroll-component";
// icons
import { IoIosArrowUp } from "react-icons/io";
// commons
import BackHeader from "components/headers/BackHeader";
import NavBottom from "components/Nav/NavBottom";
import PostCardInConyQuestion from "components/cards/PostCardInConyQuestion";
import QuestionBannerCard from "components/cards/QuestionBannerCard";
import LastQuestionBannerCard from "components/cards/LastQuestionCard";
import EmptyCard from "components/cards/EmptyCard";
import { toast, ToastContainer } from "react-toastify"; // Toastify import
import LoadingCard from "components/cards/LoadingCard";

const ConyQuestionLast = () => {
  const navigate = useNavigate();
  const [thisWeek, setThisWeek] = useState(false);
  const paramId = useParams().id;
  const getLastQuestion = useRequestGet({
    requestUrl: `/api/questions/${paramId}`,
    queryKey: `getLastQuestion-${paramId}`,
  });

  const [conyQuestionComment, setConyQuestionComment] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
  // 데이터를 가져오는 함수
  const getConyQuestionComment = async (currentPage) => {
    try {
      // 1초 딜레이를 추가
      await new Promise((resolve) => setTimeout(resolve, 200));

      const { data } = await axios.get(
        `/api/questions/${paramId}/comments?page=${currentPage}&limit=10`
      );
      const newComment = data.data;

      setConyQuestionComment((prevQuestions) => {
        const uniqueQuestions = [
          ...(prevQuestions ?? []),
          ...newComment,
        ].reduce((acc, cur) => {
          if (!acc.find((question) => question.seq === cur.seq)) {
            acc.push(cur);
          }
          return acc;
        }, []);
        return uniqueQuestions;
      });
      // setConyQuestionComment((prevComments) => [...prevComments, ...newComment]); // 기존 포스트에 새로 가져온 포스트 추가
      setHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  // 첫 로드 시 데이터를 불러옴
  useEffect(() => {
    getConyQuestionComment(page);
  }, [page]);

  // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setShowScrollUp(true);
      } else {
        setShowScrollUp(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   if ("scrollRestoration" in window.history) {
  //     window.history.scrollRestoration = "manual"; // 기본값은 'auto'
  //   }
  // }, []);

  // 다음 페이지를 불러오는 함수
  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // 맨 위로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const deletedFilter = (deletedSeq) => {
    setConyQuestionComment((prevComments) =>
      prevComments.filter((el) => el.seq !== deletedSeq)
    );
    toast.success("삭제되었습니다.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <div className="wrapper h-auto max-h-[100vh] ">
      <div className="flex justify-center h-full">
        <BackHeader
          title="지난질문"
          isAlarmShow={true}
          designatedUrl="/conyQuestion/all"
        />
        <NavBottom />
        <ToastContainer />

        <div className="bg-white minWrapper mt-[60px] mb-[70px]">
          <div className="flex flex-col gap-[15px]">
            {getLastQuestion?.data?.data &&
            getLastQuestion?.data?.data?.isActive ? (
              <QuestionBannerCard
                openDate={getLastQuestion?.data?.data?.openDate}
                title={getLastQuestion?.data?.data?.content}
                // isNavigate={true}
                // navigateUrl="/conyQuestion/1"
              />
            ) : getLastQuestion?.data?.data ? (
              <LastQuestionBannerCard
                openDate={getLastQuestion?.data?.data?.openDate}
                content={getLastQuestion?.data?.data?.content}
                seq={getLastQuestion?.data?.data?.seq}
                fowardArrow={false}
                cutting={false}
              />
            ) : (
              <></>
            )}
            <div className="mt-[15px]">
              {showScrollUp && (
                <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
                  <button type="button" onClick={scrollToTop}>
                    <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                  </button>
                </div>
              )}
              {conyQuestionComment?.length > 0 ? (
                <InfiniteScroll
                  dataLength={conyQuestionComment?.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  endMessage={
                    <>
                      {conyQuestionComment?.length > 0 && (
                        <p className="pb-4 text-center text-gray-gray">
                          모든 댓글을 불러왔습니다.
                        </p>
                      )}
                    </>
                  }
                >
                  {conyQuestionComment.map((el, idx) => (
                    <div
                      key={el.seq} // 인덱스 대신 고유한 seq 값을 key로 사용
                      onClick={() =>
                        navigate(
                          `/conyQuestion/comment/${getLastQuestion?.data?.data?.seq}/${el?.seq}?commentIdx=${idx}`
                        )
                      }
                    >
                      <PostCardInConyQuestion
                        deletedFilter={deletedFilter}
                        questionSeq={getLastQuestion?.data?.data?.seq}
                        {...el}
                      />
                    </div>
                  ))}
                </InfiniteScroll>
              ) : conyQuestionComment ? (
                <div className="mt-[100px]">
                  <EmptyCard title={"답변이 존재하지 않습니다."} />
                </div>
              ) : (
                <LoadingCard />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConyQuestionLast;
