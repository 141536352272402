import BackHeader from 'components/headers/BackHeader';

const Policy = () => {
    return (
        <div className="flex justify-center wrapper">
            <div className="minWrapper">
                <BackHeader title="이용약관" />
            </div>
        </div>
    );
};

export default Policy;
