// hooks
import { useNavigate } from "react-router-dom";
import { useRequestGet } from "hooks/useRequest";
// commons
import MainHeader from "components/headers/MainHeader";
import NavBottom from "components/Nav/NavBottom";
import PostCard from "components/cards/PostCard";
import QuestionBannerCard from "components/cards/QuestionBannerCard";
import EmptyCard from "components/cards/EmptyCard";
// import ApologizeModal from 'components/modals/ApologizeModal';

const Home = () => {
  const navigate = useNavigate();
  const getConyQuestion = useRequestGet({
    requestUrl: "/api/questions/active",
    queryKey: "getConyQuestion",
  });
  const getMostLikedConyQuestion = useRequestGet({
    requestUrl: "/api/posts/top-liked",
    queryKey: "getMostLikedConyQuestion",
  });

  const getRecentConyQuestion = useRequestGet({
    requestUrl: "/api/posts/latest",
    queryKey: "getRecentConyQuestion",
  });

  return (
    <div className="wrapper h-auto max-h-[100vh] ">
      <div className="flex justify-center">
        <MainHeader />
        <NavBottom />
        <div className="bg-white minWrapper mt-[60px] mb-[70px]">
          {/* <div className="relative">
                        <ApologizeModal />
                    </div> */}
          {getConyQuestion?.data?.data && (
            <QuestionBannerCard
              openDate={getConyQuestion?.data?.data?.openDate}
              title={getConyQuestion?.data?.data?.content}
              isNavigate={true}
              navigateUrl={`/conyQuestion/answer/${getConyQuestion?.data?.data?.seq}`}
            />
          )}
          <div className="text-[18px] font-bold mx-[22px] mt-[14px]  mb-[10px]">
            🔥인기 게시글
          </div>
          {getMostLikedConyQuestion?.data?.data ? (
            <div
              onClick={() =>
                navigate(
                  `/cogenLife/${getMostLikedConyQuestion?.data?.data.postType}/${getMostLikedConyQuestion?.data?.data.seq}`
                )
              }
            >
              <PostCard {...getMostLikedConyQuestion?.data?.data} />
            </div>
          ) : (
            <div className="h-[100px] flex justify-center items-center">
              <EmptyCard title="인기 게시글이 없습니다" />
            </div>
          )}
          <div className="text-[18px] font-bold mx-[22px] mt-[14px]  mb-[10px]">
            ⭐최근 게시글
          </div>
          {getRecentConyQuestion?.data?.data ? (
            <div
              onClick={() =>
                navigate(
                  `/cogenLife/${getRecentConyQuestion?.data?.data.postType}/${getRecentConyQuestion?.data?.data.seq}`
                )
              }
            >
              <PostCard {...getRecentConyQuestion?.data?.data} />
            </div>
          ) : (
            <div className="h-[100px] flex justify-center items-center">
              <EmptyCard title="최근 게시글이 없습니다" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
