// hooks
import { useRequestGet } from "hooks/useRequest";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "api/axios";
import InfiniteScroll from "react-infinite-scroll-component";
// commons
import BackHeader from "components/headers/BackHeader";
import NavBottom from "components/Nav/NavBottom";
import CommentCard from "components/cards/CommentCard";
import EmptyCard from "components/cards/EmptyCard";
import LoadingCard from "components/cards/LoadingCard";

// icons
import { IoIosArrowUp } from "react-icons/io";
const MyCogenComment = () => {
  const navigate = useNavigate();
  // const getMyComment = useRequestGet({
  //   requestUrl: `/api/users/my-comments`,
  //   queryKey: "getMyComment",
  // });
  const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
  const [myComments, setMyComments] = useState(null);
  const [commentPage, setCommentPage] = useState(1);
  const [commentHasMore, setCommentHasMore] = useState(true);
  const getMyComments = async (currentPage) => {
    try {
      // 1초 딜레이를 추가
      await new Promise((resolve) => setTimeout(resolve, 100));

      const { data } = await axios.get(
        `/api/users/my-comments?page=${currentPage}&limit=10`
      );
      const newComments = data.data;

      // 이전 데이터에 새 데이터를 추가
      setMyComments((prevComments) => {
        const uniqueComments = [...(prevComments ?? []), ...newComments].reduce(
          (acc, newComment) => {
            if (!acc.find((comment) => comment.seq === newComment.seq)) {
              acc.push(newComment);
            }
            return acc;
          },
          []
        );
        return uniqueComments;
      });
      setCommentHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  // 첫 로드 시 데이터를 불러옴
  useEffect(() => {
    getMyComments(commentPage);
  }, [commentPage]);

  // 다음 페이지를 불러오는 함수
  const fetchMoreCommentData = () => {
    setCommentPage((prevPage) => prevPage + 1);
  };

  // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setShowScrollUp(true);
      } else {
        setShowScrollUp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // 맨 위로 스크롤하는 함수

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="wrapper h-auto max-h-[100vh] ">
      <div className="relative flex justify-center">
        <BackHeader
          isBackshow={false}
          title="나의 댓글"
          isAlarmShow={true}
          designatedUrl={"/myCogen"}
        />
        <NavBottom />

        <div className="bg-white minWrapper mb-[70px]">
          {showScrollUp && (
            <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
              <button type="button" onClick={scrollToTop}>
                <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
              </button>
            </div>
          )}
          <div className="bg-white minWrapper pt-[60px]">
            {myComments?.length > 0 ? (
              <InfiniteScroll
                dataLength={myComments?.length}
                next={fetchMoreCommentData}
                hasMore={commentHasMore}
                endMessage={
                  <>
                    {myComments?.length > 0 && (
                      <p className="text-center text-gray-gray pb-4">
                        모든 댓글을 불러왔습니다.
                      </p>
                    )}
                  </>
                }
              >
                {myComments?.map((el, idx) => (
                  <div
                    key={idx}
                    onClick={() =>
                      navigate(`/cogenLife/${el.postType}/${el.postSeq}`)
                    }
                  >
                    <CommentCard {...el} type="COMMENT" isMessage={false} />
                  </div>
                ))}
              </InfiniteScroll>
            ) : myComments ? (
              <div className="mt-[200px]">
                <EmptyCard title={"댓글이 존재하지 않습니다."} />
              </div>
            ) : (
              <LoadingCard />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCogenComment;
