// hooks
import { useState, useEffect } from 'react';
import axios from 'api/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
// icons
import { IoIosArrowUp } from 'react-icons/io';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import LastQuestionBannerCard from 'components/cards/LastQuestionCard';
import EmptyCard from 'components/cards/EmptyCard';
import LoadingCard from 'components/cards/LoadingCard';
const ConyQuestionLast = () => {
    const [lastConyQuestions, setLastConyQuestions] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showScrollUp, setShowScrollUp] = useState(false); // 스크롤 아이콘 보이기 상태
    // 데이터를 가져오는 함수
    const getConyQuestionComment = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 200));

            const { data } = await axios.get(`/api/questions/list?page=${currentPage}&limit=10`);
            const newLastConyQuestion = data.data;

            // 이전 데이터에 새 데이터를 추가
            setLastConyQuestions((prevQuestions) => {
                const uniqueQuestions = [...(prevQuestions ?? []), ...newLastConyQuestion].reduce(
                    (acc, newQuestion) => {
                        if (!acc.find((question) => question.seq === newQuestion.seq)) {
                            acc.push(newQuestion);
                        }
                        return acc;
                    },
                    []
                );
                return uniqueQuestions;
            });

            // setLastConyQuestions((prevQuestions) => [...prevQuestions, ...newLastConyQuestion]); // 기존 포스트에 새로 가져온 포스트 추가
            setHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    // 첫 로드 시 데이터를 불러옴
    useEffect(() => {
        getConyQuestionComment(page);
    }, [page]);

    // 스크롤이 일정 높이 이상일 때 아이콘을 보이게 설정
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowScrollUp(true);
            } else {
                setShowScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // 다음 페이지를 불러오는 함수
    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);
    };

    // 맨 위로 스크롤하는 함수
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className="wrapper h-auto max-h-[100vh] ">
            <div className="flex justify-center">
                <BackHeader
                    title="지난질문"
                    isAlarmShow={true}
                    designatedUrl="/conyQuestion"
                />
                <NavBottom />
                {showScrollUp && (
                    <div className="fixed top-10 z-10 bg-white minWrapper h-[30px] flex justify-center">
                        <button
                            type="button"
                            onClick={scrollToTop}
                        >
                            <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
                        </button>
                    </div>
                )}
                <div className="bg-white minWrapper pt-[60px]">
                    <div className="flex flex-col gap-[18px] pb-[90px]">
                        {lastConyQuestions?.length > 0 ? (
                            <InfiniteScroll
                                dataLength={lastConyQuestions?.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                endMessage={
                                    <>
                                        {lastConyQuestions?.length > 0 && (
                                            <p className="text-center text-gray-gray pb-4">
                                                모든 코니질문을 불러왔습니다.
                                            </p>
                                        )}
                                    </>
                                }
                            >
                                {lastConyQuestions?.map((el, idx) =>
                                    el.isActive ? (
                                        <div
                                            key={idx}
                                            className="mb-[18px]"
                                        >
                                            <QuestionBannerCard
                                                {...el}
                                                isNavigate={true}
                                                navigateUrl={`/conyQuestion/answer/${el.seq}`}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            key={idx}
                                            className="mb-[18px]"
                                        >
                                            <LastQuestionBannerCard {...el} />
                                        </div>
                                    )
                                )}
                            </InfiniteScroll>
                        ) : lastConyQuestions ? (
                            <div className="mt-[100px]">
                                <EmptyCard title="코니 질문을 준비 중입니다" />
                            </div>
                        ) : (
                            <LoadingCard />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConyQuestionLast;
