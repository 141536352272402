// icons
import { IoClose } from "react-icons/io5";

const DefaultInput = ({
  value,
  setValue,
  placeholder = "",
  type = "whiteBorderType",
  resetBtn = false,
  inputType = "text",
}) => {
  const styleType = {
    grayType: "",
    whiteType: "",
    whiteBorderType:
      "font-semibold border rounded-full text-white flex items-center justify-between",
    transparentWhiteType:
      "font-semibold bg-[#ffffff30] text-white rounded-full flex items-center justify-between",
  };

  // 휴대폰인 경우? inputType===phone
  const formatPhoneNumber = (input) => {
    // 입력 중 자동으로 '-' 추가하는 함수
    const cleaned = input.replace(/\D/g, ""); // 숫자만 남김
    const match = cleaned.match(/^(\d{3})(\d{0,4})(\d{0,4})$/);
    if (match) {
      return `${match[1]}${match[2] ? "-" + match[2] : ""}${
        match[3] ? "-" + match[3] : ""
      }`;
    }
    return input;
  };
  const handlePhoneChange = (number) => {
    const formattedPhone = formatPhoneNumber(number);
    setValue(formattedPhone);
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // 기본 제출 동작 방지
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={`${styleType[type]}`}>
        <input
          value={value}
          placeholder={placeholder}
          className="w-full p-4 text-[16px] placeholder-white bg-transparent border-none outline-none focus:outline-none"
          type={inputType}
          onChange={(e) => {
            inputType === "phone"
              ? handlePhoneChange(e.target.value)
              : setValue(e.target.value);
          }}
          autoComplete="off"
        />
        {resetBtn && (
          <IoClose
            onClick={() => setValue("")}
            className="w-10 h-10 p-2 mr-2 text-white"
          />
        )}
      </div>
    </form>
  );
};

export default DefaultInput;
