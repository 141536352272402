// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestPatch } from 'hooks/useRequest';
// common
import VerfyInput from 'components/inputs/VerifyInput';
import CloseHeader from 'components/headers/CloseHeader';
import { toast, ToastContainer } from 'react-toastify'; // Toastify import

const MyCogenEditPW = () => {
    const navigate = useNavigate();
    const [pw, setPW] = useState('');
    const [verifyPW, setVerifyPW] = useState('');

    const [newPW, setNewPW] = useState('');
    const [isValidNewPW, setIsValidNewPW] = useState('');

    const [certificateNewPW, setCertificateNewPW] = useState('');
    const [isMatchedPW, setIsMatchedPW] = useState('');
    const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;

    useEffect(() => {
        if (pw?.length > 0) {
            if (pwRegex.test(pw)) {
                setVerifyPW(true);
            } else {
                setVerifyPW({
                    state: 'error',
                    message: '문자와 숫자를 포함하여 8자 이상 15자 이하로 입력하세요.',
                });
            }
        }
    }, [pw]);
    useEffect(() => {
        if (newPW?.length > 0) {
            if (pwRegex.test(newPW)) {
                setIsValidNewPW(true);
            } else if (pw.length <= 0) setIsValidNewPW(false);
            else {
                setIsValidNewPW({
                    state: 'error',
                    message: '문자와 숫자를 포함하여 8자 이상 15자 이하로 입력하세요.',
                });
            }
        } else setIsValidNewPW(false);
    }, [newPW]);
    useEffect(() => {
        if (certificateNewPW?.length > 0) {
            if (newPW && certificateNewPW === newPW) {
                setIsMatchedPW({
                    state: 'success',
                    message: '새 비밀번호가 일치합니다.',
                });
            } else
                setIsMatchedPW({
                    state: 'error',
                    message: '새 비밀번호가 일치하지 않습니다.',
                });
        } else setIsMatchedPW(false);
    }, [certificateNewPW, pw]);

    // patchResetPW
    const patchResetPW = useRequestPatch();
    const handlePatchResetPW = () => {
        if (pw && verifyPW && isValidNewPW && isValidNewPW?.state !== 'error' && isMatchedPW?.state === 'success')
            patchResetPW.mutate({
                requestUrl: '/api/users/password/reset',
                patchData: {
                    currentPassword: pw,
                    newPassword: newPW,
                    confirmNewPassword: certificateNewPW,
                },
            });
    };
    useEffect(() => {
        if (patchResetPW?.status === 'success') {
            if (patchResetPW?.data?.response?.status === 400) {
                toast.error(patchResetPW?.data?.response?.data?.message || '정확한 정보를 입력하세요', {
                    position: 'top-center',
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else if (patchResetPW?.data?.status === 200) {
                toast.success('비밀번호가 변경되었습니다.', {
                    position: 'top-center',
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                toast.error(patchResetPW?.data?.response?.data?.message || '정확한 정보를 입력하세요', {
                    position: 'top-center',
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }, [patchResetPW.status]);
    return (
        <div className="wrapper ">
            <ToastContainer />
            <div className="bg-white minWrapper pt-[60px] h-full m-auto">
                <CloseHeader
                    title="비밀번호 재설정"
                    postTitle="저장"
                    cloesNavigateURL={'/myCogen'}
                    activeFunc={handlePatchResetPW}
                />
                <div className="p-[14px]">
                    <VerfyInput
                        value={pw}
                        setValue={setPW}
                        placeholder="기존 비밀번호"
                        type="grayType"
                        // onChangeFunc={handleSubmitPW}
                        state={verifyPW?.state}
                        stateMessage={verifyPW?.message}
                        inputType="password"
                    />
                    <div className="my-[14px]"></div>
                    <VerfyInput
                        value={newPW}
                        setValue={setNewPW}
                        placeholder="새 비밀번호"
                        type="grayType"
                        autoFocus={false}
                        state={isValidNewPW?.state}
                        stateMessage={isValidNewPW?.message}
                        inputType="password"
                    />
                    <div className="my-[14px]"></div>
                    <VerfyInput
                        value={certificateNewPW}
                        setValue={setCertificateNewPW}
                        placeholder="새 비밀번호 확인"
                        type="grayType"
                        autoFocus={false}
                        state={isMatchedPW?.state}
                        stateMessage={isMatchedPW?.message}
                        inputType="password"
                    />
                </div>
            </div>
        </div>
    );
};

export default MyCogenEditPW;
