// hooks
import { useNavigate } from "react-router-dom";
// icons
import { IoIosArrowForward } from "react-icons/io";

const LastQuestionBannerCard = ({
  content,
  createdAt,
  isActive,
  modifiedAt,
  openDate,
  seq,
  fowardArrow = true,
  cutting = true,
}) => {
  const navigate = useNavigate();

  // ISO 표준 주차 계산!
  const getNthWeekOfMonth = (dateString) => {
    const date = new Date(dateString);
    // 한국 시간으로 변환 (UTC + 9시간)
    const KSTDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);

    const dayOfMonth = KSTDate.getDate();
    const firstDayOfMonth = new Date(
      KSTDate.getFullYear(),
      KSTDate.getMonth(),
      1
    );
    const firstDayOfWeek = firstDayOfMonth.getDay() || 7; // 일요일을 7로 변경 (ISO 8601 기준 월요일 시작)

    // 첫 번째 목요일 찾기
    const firstThursday = new Date(firstDayOfMonth);
    while (firstThursday.getDay() !== 4) {
      firstThursday.setDate(firstThursday.getDate() + 1);
    }

    // 현재 날짜가 첫 번째 목요일 이후 몇 번째 주인지 계산
    const diff = Math.ceil(
      (dayOfMonth - firstThursday.getDate() + firstDayOfWeek + 3) / 7
    );
    return Math.max(1, diff);
  };

  const getWeekRange = (dateString) => {
    const date = new Date(dateString);
    // 한국 시간으로 변환
    const KSTDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);

    const dayOfWeek = KSTDate.getDay(); // 0: 일요일 ~ 6: 토요일
    const startOfWeek = new Date(KSTDate);
    const endOfWeek = new Date(KSTDate);

    startOfWeek.setDate(
      KSTDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );
    endOfWeek.setDate(
      KSTDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek)
    );

    const format = (d) =>
      `${d.getFullYear()}.${(d.getMonth() + 1).toString().padStart(2, "0")}.${d
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    return `${format(startOfWeek)} ~ ${format(endOfWeek)?.slice(5)}`;
  };

  const nthWeek = getNthWeekOfMonth(openDate);
  const weekRange = getWeekRange(openDate);
  const date = new Date(openDate);
  return (
    <div
      onClick={() => navigate(`/conyQuestion/all/${seq}`)}
      className="bg-gray-lGray text-white p-[20px] min-h-[150px] rounded-[15px] relative mx-[22px] flex flex-col justify-between"
    >
      <div className="flex items-center">
        <div className="text-gray-gray text-[14px] bg-white py-1 rounded-[5px] font-bold mr-3 px-2 round">
          {date.getMonth() + 1}월 {nthWeek}째주 질문
        </div>
        <div className="text-[14px] font-bold">{weekRange}</div>
      </div>

      <div className=" mt-[40px] h-full flex items-end justify-between text-lg font-semibold">
        <div
          className={` w-[92%] ${
            cutting &&
            "max-h-[70px] break-words leading-tight text-ellipsis break-keep line-clamp-3"
          }`}
        >
          {content && content}
        </div>
        {fowardArrow && (
          <IoIosArrowForward className="w-[24px] h-[24px] flex-shrink-0" />
        )}
      </div>
    </div>
  );
};

export default LastQuestionBannerCard;
