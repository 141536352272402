// hooks
import React, { useEffect, useState } from 'react';
// icons
import { IoClose } from 'react-icons/io5';
import { FaImage } from 'react-icons/fa6';
import { TbCameraFilled } from 'react-icons/tb';
// dnd
import { DndContext, PointerSensor, useSensor, useSensors, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// common
import { toast, ToastContainer } from 'react-toastify'; // Toastify import

function SortableItem({ idx, id, url, onRemove }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'none', // 터치 이벤트 개선
    };

    return (
        <div
            className="relative w-full"
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <div className="absolute top-0 left-0 w-full h-full border-[1.5px] border-dashed border-gray-lGray bg-[#F8F8F8] rounded-[15px] flex flex-col justify-center items-center overflow-hidden">
                <img
                    src={url}
                    alt="Preview"
                    draggable="false"
                    className="w-full h-full object-cover rounded-[14px]"
                />
                <button
                    type="button"
                    className="absolute p-1 text-white bg-text-dGray rounded-full flex justify-center items-center top-2 right-2 w-[30px] h-[30px]"
                    onClick={(e) => {
                        // e.stopPropagation();
                        onRemove(id);
                    }}
                >
                    <IoClose className="w-[20px] h-[20px]" />
                </button>
            </div>
            <div className="absolute flex items-center justify-center left-[41%] top-[40%] select-none">
                <div className="w-[30px] h-[30px] bg-main-red text-white rounded-full flex justify-center items-center font-bold text-[14px]">
                    {String(idx + 1).padStart(2, '0')}
                </div>
            </div>
            <div className="pb-[100%]" />
        </div>
    );
}

const ImageUploader = ({ selectImages, setSelectImages }) => {
    const [isShow, setIsShow] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(selectImages);
    }, [selectImages]);
    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + items.length > 5) {
            alert('최대 5개의 이미지만 업로드할 수 있습니다.');
            return;
        }

        const newItems = files.map((file) => ({
            id: `${Date.now()}-${file.name}`,
            url: URL.createObjectURL(file),
            file: file, // 실제 파일 객체를 저장
        }));

        setItems((prevItems) => [...prevItems, ...newItems]);
    };

    const handleRemoveImage = (id) => {
        setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5, // 혹은 이 값을 0으로 설정해보세요
            },
        })
    );

    const saveImage = () => {
        setSelectImages(items);
        const validExtensions = ['jpg', 'jpeg', 'png', 'heif', 'heic'];
        const validItems = items.filter((item) => {
            if (item && item.id) {
                // console.log("item", item);
                const splittedId = item.id.split('.');
                const extension = splittedId[splittedId?.length - 1].toLowerCase();
                const isValid = validExtensions.includes(extension);
                if (item && item.url && !isValid) {
                    // console.log("HTTPitem", item.url.slice(0, 5), "https");
                    return item.url.slice(0, 5) === 'https';
                }
                return isValid;
            }

            return false; // item.name이 없으면 해당 파일은 유효하지 않음
        });
        setSelectImages(validItems || []);
        if (validItems.length !== items.length && isShow) {
            alert('지원되지 않는 이미지 파일 확장자는 제외됩니다.');
            setIsShow(false);
        } else {
            // 이미지 선택 UI 닫기
            setIsShow(false);
        }
    };

    return (
        <div>
            <ToastContainer />
            <div
                onClick={() => setIsShow(true)}
                className="h-[50px] flex items-center justify-center border border-gray-gray text-gray-gray mt-2 mb-4 rounded-full text-[16px] font-bold"
            >
                <FaImage className="mr-2 text-gray-lGray " />
                사진추가
            </div>
            {isShow && (
                <div className="fixed h-[100vh] top-0 left-0 z-30 flex justify-center w-full wrapper">
                    <div className="h-full bg-white minWrapper">
                        <div className="fixed top-0 bg-white flex justify-between minWrapper px-2 h-[44px] items-center text-[20px] font-bold">
                            <div
                                className="w-50 h-50"
                                onClick={() => setIsShow(false)}
                            >
                                <IoClose className="w-7 h-7" />
                            </div>
                            <div>사진추가</div>
                            <button
                                type="button"
                                onClick={() => saveImage()}
                                className="w-[65px] h-[50px] text-text-lGray flex items-center"
                            >
                                <span className="mr-1 text-main-red">{items.length}</span>
                                <span>등록</span>
                            </button>
                        </div>
                        <div className="mt-[60px] px-[22px] text-[16px] mb-2 text-text-gray">
                            <div>사진은 5개까지 첨부할 수 있어요</div>
                        </div>

                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={items}
                                strategy={verticalListSortingStrategy}
                            >
                                <div className="grid grid-cols-3 gap-3 px-[22px]">
                                    {/* 사진 업로드 버튼 */}
                                    <div className="relative w-full">
                                        <input
                                            type="file"
                                            // accept=".jpg,.jpeg,.png,.heif,.heic"
                                            multiple
                                            onChange={handleImageUpload}
                                            className="absolute z-10 w-full h-full opacity-0 cursor-pointer"
                                            accept="image/*"
                                        />
                                        <div className="absolute top-0 left-0 w-full h-full border-2 border-dashed border-gray-lGray bg-[#F8F8F8] rounded-[15px] flex flex-col justify-center items-center">
                                            <TbCameraFilled className="w-[25px] h-[25px] text-gray-gray" />
                                            <div className="font-bold text-gray-gray text-[16px] mt-2">카메라</div>
                                        </div>
                                        <div className="pb-[100%]" />
                                    </div>
                                    {/* 드래그 앤 드롭 가능한 이미지들 */}
                                    {items.map((item, idx) => (
                                        <SortableItem
                                            idx={idx}
                                            key={item.id}
                                            id={item.id}
                                            url={item.url}
                                            onRemove={handleRemoveImage}
                                        />
                                    ))}
                                </div>
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageUploader;
