// hooks
import { useState, useEffect } from "react";
import { useRequestGet, useRequestDelete } from "hooks/useRequest";
import { useNavigate } from "react-router-dom";
//common
import DeleteComfirmModal from "components/modals/DeleteComfirmModal";
// icons
import editIcon from "assets/images/common/editIcon.png";
import deleteIcon from "assets/images/common/deleteIcon.png";
import { FaUserCircle } from "react-icons/fa";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { IoHeart } from "react-icons/io5";
// utils
import {
  ageGroupStyleMatcher,
  postCategoryMathcnerByEng,
  dateMatcher,
} from "utils/matcher";
const PostCardConyQuestion = ({
  deletedFilter,
  questionSeq,
  authorAgeGroup,
  authorNickname,
  content,
  isLiked = false,
  likeCount = 0,
  replies = [],
  createdAt,
  modifiedAt,
  seq,
  authorProfileImageUrl,
}) => {
  const navigate = useNavigate();
  // 현재 사용자 정보 조회
  const getUserInfo = useRequestGet({
    requestUrl: "/api/users/profile",
    queryKey: "getUserInfo",
  });

  // 삭제 기능 구현
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteComment = useRequestDelete();
  const handleDeleteComment = () => {
    deleteComment.mutate({
      requestUrl: `/api/questions/${questionSeq}/comments/${seq}`,
    });
  };
  useEffect(() => {
    if (deleteComment.status === "success") {
      deletedFilter(seq);
    }
  }, [deleteComment.status]);

  return (
    <>
      <div className="border border-gray-sGray p-[20px] rounded-[15px] mx-[22px] mb-[15px] bg-white ">
        <div className="flex justify-between ">
          <div className="flex-grow">
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                {authorProfileImageUrl ? (
                  <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
                    <img
                      src={authorProfileImageUrl}
                      alt={"authorProfileImageUrl"}
                      className="w-full h-full rounded-full"
                    />
                  </div>
                ) : (
                  <div className="rounded-full  w-[36px] h-[36px]">
                    <FaUserCircle className="w-full h-full text-gray-lGray" />
                  </div>
                )}
                <div>
                  <div>
                    {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
                  </div>
                  <div className="text-text-lGray text-[14px] ml-2 font-bold mt-[-2px]">
                    {dateMatcher(createdAt)}
                  </div>
                </div>
              </div>
              <div>
                {getUserInfo?.data?.data?.nickname &&
                  getUserInfo?.data?.data?.nickname === authorNickname && (
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();

                          navigate(
                            `/conyQuestion/answerEdit/${questionSeq}/${seq}`,
                            {
                              state: { content: content },
                            }
                          );
                        }}
                        className="flex items-center"
                      >
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-[20px] h-[20px]"
                        />
                        <div className="text-[14px] bold text-text-gray">
                          수정
                        </div>
                      </button>
                      <DeleteComfirmModal
                        isShow={isDeleteModal}
                        setIsShow={setIsDeleteModal}
                        title={"삭제하시겠습니까?"}
                        modalBtn={
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsDeleteModal(true);
                            }}
                            className="flex items-center ml-[2px]"
                          >
                            <img
                              src={deleteIcon}
                              alt="deleteIcon"
                              className="w-[20px] h-[20px]"
                            />
                            <div className="text-[14px] bold text-main-red">
                              삭제
                            </div>
                          </button>
                        }
                        activeFunc={handleDeleteComment}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="text-[14px] text-text-dGray mt-1 whitespace-pre-line break-all">
              {content}
            </div>
            <div>
              <div className="flex items-center font-semibold mt-[5px]">
                <HiMiniChatBubbleOvalLeft className="mr-1 w-[14px] h-[14px] text-gray-lGray" />
                <div className="text-[14px] mr-2 text-text-gray">
                  {replies.length || 0}
                </div>
                <div className="flex items-center">
                  <IoHeart
                    className={"text-gray-lGray w-[14px] mr-[2px] h-[14px]"}
                  />
                  <span className={"text-[14px] font-bold text-text-gray"}>
                    {likeCount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCardConyQuestion;
