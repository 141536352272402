// hooks
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
const CogenLifeSearchHeader = ({ searchInput, setSearchInput, activeFunc }) => {
  const navigate = useNavigate();
  const paramsCategory = useParams()?.category;
  return (
    <div className="h-[50px] bg-white px-3 flex justify-between items-center fixed minWrapper z-10">
      <div className="flex items-center">
        {/* <div className="w-[50px] bg-red-50 h-[50px] flex items-center justify-center"> */}
        <IoIosArrowBack onClick={() => navigate(-1)} className="w-7 h-7" />
        {/* </div> */}

        <input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="text-[20px] w-full ml-3 py-1 focus:outline-none"
          placeholder="검색해주세요"
          autoFocus
        />
      </div>
      <div className="flex items-center ">
        <div
          onClick={() => activeFunc()}
          className="relative w-[50px] h-[50px] flex justify-center items-center"
        >
          <IoSearch className="w-[27px] h-[27px] text-main-red" />
        </div>
      </div>
    </div>
  );
};

export default CogenLifeSearchHeader;
