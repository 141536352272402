// hooks
import { CgClose } from "react-icons/cg";

const DeleteComfirmModal = ({
  title,
  isShow,
  setIsShow,
  modalBtn,
  activeFunc,
}) => {
  const handleActive = () => {
    if (activeFunc) activeFunc();
    setIsShow(false);
  };

  return (
    <>
      {modalBtn}
      {isShow && (
        <div
          onClick={() => setIsShow(false)}
          className="w-screen h-screen z-[200] fixed inset-0 bg-[#00000040] flex justify-center items-center"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative bg-white rounded-[10px] p-[22px] max-w-[300px] w-full h-full max-h-[150px] flex flex-col justify-center items-center"
          >
            <CgClose
              className="absolute top-5 right-5"
              onClick={() => setIsShow(false)}
            />
            <div className="font-bold mt-[30px]">{title}</div>
            <div className="mt-[30px] flex gap-4 font-bold justify-end w-full px-[10px]">
              <button
                type="button"
                onClick={() => setIsShow(false)}
                className="text-gray-gray"
              >
                취소
              </button>
              <button
                type="button"
                onClick={() => handleActive()}
                className="text-main-red"
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteComfirmModal;
